import { SET_NEW_DIARY } from "../../action-types/diaries"
import { request } from "../../../api/axios_helper"
import { DIARIES_API_URL } from "../../../common/constants"
import { setDiariesLoadingProgress, getDiaries } from "./get-diaries"

export const setNewDiary = (payload) => ({
  type: SET_NEW_DIARY,
  payload,
})

export const createDiaryActivity =
  (data, isUpdate = true) =>
  (dispatch) => {
    dispatch(setDiariesLoadingProgress(true))
    return request()
      .create(DIARIES_API_URL, data)
      .then((res) => {
        dispatch(setNewDiary(res.data))
        isUpdate && dispatch(getDiaries(false))
        return res.data
      })
      .finally(() => dispatch(setDiariesLoadingProgress(false)))
  }
