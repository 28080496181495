/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "antd"
import Icon, { PlusOutlined } from "@ant-design/icons"
import { NoteEditor } from "../notes/noteEditor"
import { QuilEditor } from "../editor/quil-editor"
import { getSpecificNote } from "../../store/selectors/notes/get_notes_selector"
import { updateDiary } from "../../store/actions/diaries/edit-diary"
import { removeDiaryTH } from "../../store/actions/diaries/delete-diary"
import { createDiaryActivity } from "../../store/actions/diaries/create-diary"
import { setSpecificNote } from "../../store/actions/notes/create_note"
import Spinner from "../loaders/spinner"
import { debounce } from "lodash"



const DiaryContent = ({ diaryHtml, diaryContent = "", setDiaryHtml, setDiaryContent }) => {
    const dispatch = useDispatch()
    const specificNote = useSelector(getSpecificNote)
    const editorRef = useRef()
    const [loadingEditor, setLoadingEditor] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [showEditor, setShowEditor] = useState(false)
    const [isLess, setIsLess] = useState(false)
    const [changeData, setChangeData] = useState({
        ...specificNote,
        body_txt: diaryContent,
        body_html: diaryHtml,
    })
    const [data, setData] = useState({
        ...specificNote,
        body_txt: diaryContent,
        body_html: diaryHtml,
    })
    const handleOpenEditor = useCallback(() => {
        dispatch(setSpecificNote(data))
        setShowEditor(true)
    }, [dispatch, data])

    const handleCloseEditor = useCallback(() => {
        setShowEditor(false)
        document.querySelector(".ant-drawer").focus()
    }, [])

    const handleCloseQuill = useCallback(() => {
        setIsEdit(false)
    }, [])
    const handleAddNote = useCallback(
        (d, _d) => {
            const form = d || _d
            !form.company_id && delete form.company_id
            !form.workspace_id && delete form.workspace_id
            dispatch(createDiaryActivity(form)).then((res) => {
                setData(res)
                // setChangeData(res)
                dispatch(setSpecificNote(res))
            })

            handleCloseEditor()
        },
        [dispatch, handleCloseEditor]
    )
    const handleEditDiary = useCallback(
        (_, _data) => {
            !_data.company_id && delete _data.company_id
            !_data.workspace_id && delete _data.workspace_id
            dispatch(updateDiary(_data)).then((res) => {
                setData(res.payload)
                // setChangeData(res.payload)
                dispatch(setSpecificNote(res.payload))
            })

            handleCloseEditor()
        },
        [handleCloseEditor, dispatch]
    )

    const handleDeleteNote = useCallback(() => {
        dispatch(removeDiaryTH(specificNote.id))
        dispatch(setSpecificNote({}))
        setData({})
        setChangeData({})
        handleCloseEditor()
        setDiaryHtml("")
        setDiaryContent("")
    }, [dispatch, handleCloseEditor, setDiaryContent, setDiaryHtml, specificNote.id])

    const actionAfterTyping = (d) => {
        setLoadingEditor(true)
        delete d.company_id
        delete d.workspace_id
        delete d.atts
        delete d.tags
        delete d.links
        if (d.id) {
            dispatch(updateDiary(d)).then((res) => {
                setData(res.payload)
                // setChangeData(res.payload)
                dispatch(setSpecificNote(res.payload))
            }).finally(() => setLoadingEditor(false))
        } else {
            dispatch(createDiaryActivity(d)).then((res) => {
                setData(res)
                // setChangeData(res)
                dispatch(setSpecificNote(res))
            }).finally(() => setLoadingEditor(false))
        }

    }

    const debouncedAction = useCallback(debounce(actionAfterTyping, 1000), [])

    useEffect(() => {
        return () => {
            debouncedAction.cancel()
        }
    }, [debouncedAction])
    const onNoteChange = useCallback(
        (body_html, deltaObj, source, editor) => {
            if (editorRef?.current?.focus) editorRef.current.focus()
            setChangeData({
                ...changeData,
                body_html,
                body_editor: editor.getContents(),
                body_txt: editor.getText(),
            })
            if (source === 'user' && !loadingEditor) debouncedAction({
                ...changeData,
                body_html,
                body_editor: editor.getContents(),
                body_txt: editor.getText(),
            })
        },
        [changeData]
    )
    const handleEditNote = useCallback((_, d) => {
        if (!isEdit) {
            setIsEdit(true)
            return
        }

        const val = { ...changeData, tags: [], id: specificNote.id }
        !val.company_id && delete val.company_id
        delete val.workspace_id
        if (!val.id) {
            dispatch(createDiaryActivity(val)).then((res) => {
                setData(res)
                // setChangeData(res)
                dispatch(setSpecificNote(res))
            })
            setIsEdit(false)
            return
        }
        dispatch(updateDiary(val)).then((res) => {
            setData(res.payload)
            // setChangeData(res.payload)
            dispatch(setSpecificNote(res.payload))
        })

        setIsEdit(false)
    }, [changeData, isEdit, specificNote, dispatch])
    const toggleSeeMore = useCallback((e) => {
        e.stopPropagation()
        setIsLess((prev) => !prev)
    }, [])

    const onAutoSaveCallback = useCallback(res => {
        setData(res)
        setChangeData(res)
    }, [])



    const DiaryEditSvg = () => (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0932 1.79298L10.0163 0.715577C9.99419 0.694008 9.9793 0.666277 9.97211 0.635978C9.96544 0.605679 9.96698 0.574353 9.97725 0.545081C9.987 0.515809 10.0055 0.490132 10.0291 0.470618C10.0533 0.451103 10.0825 0.438778 10.1133 0.435183L13.8149 0.00175591C13.9202 -0.010569 14.0106 0.0782732 13.9983 0.185603L13.5648 3.8872C13.5484 4.02328 13.3815 4.0808 13.2844 3.98426L12.2034 2.90274L6.91707 8.18912C6.85287 8.25331 6.74811 8.25331 6.68392 8.18912L5.80885 7.31405C5.74517 7.25037 5.74517 7.14509 5.80885 7.0809L11.0932 1.79298Z"
                fill="black"
                fillOpacity="0.85"
            />
            <path
                d="M13.4399 14H0.560122C0.250443 14 0 13.75 0 13.4403V0.560122C0 0.250443 0.250443 0 0.560122 0H6.85997C6.93706 0 7.00022 0.0631542 7.00022 0.140247V1.12024C7.00022 1.19734 6.93706 1.26006 6.85997 1.26006H1.26006V12.7404H12.7399V7.14003C12.7399 7.06337 12.8031 7.00022 12.8802 7.00022H13.8602C13.9368 7.00022 14 7.06337 14 7.14003V13.4403C14 13.75 13.75 14 13.4399 14Z"
                fill="black"
                fillOpacity="0.85"
            />
        </svg>
    )

    const CloseIconSvg = () => (
        <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 1L12 12"
                stroke="black"
                strokeOpacity="0.85"
                strokeWidth="1.5"
            />
            <path
                d="M12 1L1 12"
                stroke="black"
                strokeOpacity="0.85"
                strokeWidth="1.5"
            />
        </svg>
    )

    const content = data.body_txt
    const hasContent = !!data.body_txt
    const contentIsLarge = Boolean(content && content.length > 300)
    const _content =
        !isLess && hasContent && contentIsLarge
            ? `${content?.substring(0, 300)}...`
            : content
    return (
        <>
            <div
                onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.key === "Escape" && isEdit) {
                        handleCloseQuill()
                        document.querySelector(".ant-drawer").focus()
                    }
                }}
                className="diary-details"
                style={
                    !isEdit
                        ? {
                            border: hasContent ? "1px solid #F0F0F0" : "none",
                            padding: "9px 20px",
                            marginTop: "22px",
                            marginBottom: "18px",
                        }
                        : {
                            border: "1px solid #F0F0F0",
                            padding: "14px",
                            marginTop: "22px",
                            marginBottom: "18px",
                        }
                }
            >
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "14px",
                        alignItems: "center",
                        padding: "0 4px",
                        marginLeft: "-31px",
                        maxWidth: "545px",
                        width: "100%",
                    }}
                >
                    <span
                        style={
                            !isEdit
                                ? { fontWeight: "500", marginLeft: "5px", fontSize: "18px" }
                                : { fontWeight: "500", marginLeft: "11px", fontSize: "18px" }
                        }
                    >
                        Daily Musings
                    </span>
                    <div
                        className={
                            isEdit
                                ? "is-edit edit-header comm details"
                                : "edit-header comm details"
                        }
                    >
                        {isEdit && (
                            <Button
                                style={{ margin: 0 }}
                                className="diary-edit-btn close"
                                onClick={handleCloseQuill}
                            >
                                <Icon component={CloseIconSvg} />
                            </Button>
                        )}
                        {!hasContent && <Button style={{ position: "absolute", right: "-10px", top: '-9px' }} onClick={handleOpenEditor} type="primary" size={"small"}>
                            <PlusOutlined />
                            New
                        </Button>}
                    </div>
                </div>
                <div>
                    {isEdit ? (

                        <div className="diary-quill-editor" style={{ position: "relative" }}>
                            <div style={{ position: "absolute", right: "5%", top: "2.5%" }}>{loadingEditor && <Spinner />}</div>
                            <QuilEditor
                                editorRef={editorRef}
                                content={changeData.body_html}
                                onChange={onNoteChange}
                                is_diary
                                focus
                            />
                        </div>
                    ) : (
                        <div className="df" >
                            <div onClick={handleEditNote}>
                                {_content}
                            </div>
                            <Icon
                                component={DiaryEditSvg}
                                style={{ marginRight: "-7px", marginTop: "4px", display: hasContent ? "block" : "none" }}
                                onClick={handleOpenEditor}
                            />
                        </div>
                    )}
                </div>
                {contentIsLarge && !isEdit && (
                    <span onClick={toggleSeeMore} className="link">
                        {isLess ? "Show Less" : "See More"}
                    </span>
                )}
            </div>
            {showEditor && (
                <NoteEditor
                    visible={showEditor}
                    onClose={handleCloseEditor}
                    handleEditNote={handleEditDiary}
                    handleDeleteNote={handleDeleteNote}
                    handleAddNote={handleAddNote}
                    onSaveCallback={onAutoSaveCallback}
                    is_diary
                />
            )}
        </>
    )

}


export default DiaryContent