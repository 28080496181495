import jwt_decode from "jwt-decode"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import * as yup from "yup"
import momentTZ from "moment-timezone"
import { URLS } from "./urls"
import {
  FeaturesTypes,
  JOB_APPLIED,
  JOB_DISCARDED,
  LEAD_STATUSES,
  redirectExclusionUrls,
  nameLead,
  SEARCH_MODE,
} from "./constants"
import {
  CommentOutlined,
  LinkOutlined,
  PaperClipOutlined,
  UserOutlined,
  BookOutlined,
} from "@ant-design/icons"
import { ReactComponent as JobIcon } from "../assets/images/job_icon.svg"
import { ReactComponent as CompanyIcon } from "../assets/images/company_icon.svg"
import { ReactComponent as NoteIcon } from "../assets/images/note_icon.svg"
import { ReactComponent as TodoIcon } from "../assets/images/TasksPending.svg"
import { ReactComponent as TodoDoneIcon } from "../assets/images/TaskChecked_Grey.svg"

export const jwtParse = (token) => {
  return jwt_decode(token)
}

export const generateId = () => {
  return uuidv4()
}
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const getRandomColor = (allColors, tags) => {
  if (!allColors || !tags) {
    return ""
  }
  return Object.values(allColors)
    .map((color) => color.name?.toLowerCase())
    .filter((item) => !tags.map((item) => item.color).includes(item))[0]
}

export const getNextColor = (tags, allColors) => {
  if (!allColors || !tags || tags.length === 0) {
    // If no tags are provided, return the first color
    return allColors[Object.keys(allColors)[0]].name.toLowerCase()
  }

  // Extract color names and convert them to lowercase for consistency
  const colorNames = Object.values(allColors).map((color) =>
    color.name?.toLowerCase()
  )

  // Find the last used color from the tags array
  const lastUsedColor = tags[tags.length - 1].color.toLowerCase()

  // Find the index of the last used color in the colorNames array
  const lastUsedIndex = colorNames.indexOf(lastUsedColor)

  // Determine the next color's index; if at end, wrap to start
  const nextIndex = (lastUsedIndex + 1) % colorNames.length
  const nextColor = colorNames[nextIndex]

  return nextColor
}

export const filteredColor = (tags, allColors) => {
  return getRandomColor(allColors, tags)?.trim()
    ? allColors?.[getRandomColor(allColors, tags)]
    : Object.values(allColors)[0]
}

export const removeExtension = (str) => {
  if (!str || typeof str !== "string") {
    return ""
  }
  if (!str.includes(".")) {
    return str
  }
  return str.substring(0, str.lastIndexOf("."))
}

export const renderTitleByType = (value, type) => {
  if (!value || !type) {
    return ""
  }
  switch (type) {
    case FeaturesTypes.LEAD: {
      return `${value.title ? value.title + " @" : ""} ${
        value?.company?.name || ""
      }`
    }
    case FeaturesTypes.CONTACT: {
      return `${value.first_name || ""}${
        value?.company?.name ? " @ " + value?.company?.name : ""
      }`
    }
    case FeaturesTypes.COMPANY: {
      return `${value.name || ""}${value.city ? ", " + value.city : ""}`
    }
    case FeaturesTypes.COMMUNICATION: {
      return `${value.body || ""}`
    }
    case FeaturesTypes.NOTE: {
      if (value?.title?.trim()) {
        return value.title
      }
      return cutStringIfLarge(value?.body_txt, 150, true)
    }
    case FeaturesTypes.FILES: {
      return value.title?.trim()
        ? removeExtension(value.title)
        : removeExtension(value.orig_name)
    }
    case FeaturesTypes.LINK: {
      return `${value.caption || value.url}`
    }
    case FeaturesTypes.TODO: {
      return `${value.description || ""}`
    }
    case FeaturesTypes.TODO_DONE: {
      return `${value.description || ""}`
    }
    default: {
      return ""
    }
  }
}

export const generateNewTag = (tagName, allColors, tags, existingTag) => {
  if (!isEmpty(existingTag)) {
    return existingTag
  }

  return {
    tag: tagName,
    color: getNextColor(tags, allColors),
  }
}

export const calculateLastPosition = (event, callback) => {
  const container = event.target
  if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
    callback()
  }
}

export const onOutsideClickOnTag = (
  event,
  setInputValue,
  setAddTagStep,
  addTagStep
) => {
  if (addTagStep === 0) {
    return
  }
  const deleteTagPopconfirmEl = event.path?.find(
    (el) => el.classList && el.classList.contains("delete-popconfirm-tag")
  )
  if (!deleteTagPopconfirmEl) {
    setAddTagStep(0)
    setInputValue("")
  }
}

export const addTagUtil = async (
  tags = [],
  inputValue,
  setInputValue,
  allColors,
  tag,
  createTag,
  append
) => {
  const tagIsExist = tags.some((item) => item.tag === inputValue)
  if (tagIsExist || !inputValue) {
    return
  }
  const _tag = generateNewTag(inputValue, allColors, [...(tags || [])], tag)
  if (!tag && Boolean(_tag.tag.trim())) {
    await createTag(_tag)
  }
  setInputValue("")
  Boolean(_tag.tag.trim()) && append([...tags].concat(_tag))
}
export const settingsValidation = yup.object().shape({
  tz: yup.string().oneOf(momentTZ.tz.names()).required(),
  username: yup.string().required(),
  email: yup.string().email().required(),
  first_name: yup.string().required(),
})

export const checkRedirect = function () {
  const { profile, history, location, isAuth } = this.props
  if (isAuth === undefined) {
    return
  }
  if (URLS.FORGOT_PASS_CONFIRM === location.pathname) {
    return
  }
  if (URLS.REGISTER === location.pathname) {
    return
  }
  if (URLS.HELP === location.pathname) {
    return
  }

  if (!isAuth) {
    history.push(URLS.LOGIN)
    return
  }
  if (!profile.profile) {
    return
  }
  const { redirect_profile } = profile.profile
  if (
    redirect_profile?.next === "dash" &&
    ![
      URLS.LEADS,
      URLS.COMPANY,
      URLS.HELP,
      URLS.CONTACTS,
      URLS.VERIFY,
      URLS.HOME,
    ].includes(location.pathname) &&
    !location.pathname.includes(URLS.LIBRARY)
  ) {
    history.push(URLS.HOME)
    return
  }

  if (
    redirect_profile?.next === "subscription" ||
    redirect_profile?.next === "profile"
  ) {
    history.push(URLS.VERIFY)
    return
  }
  if (location.pathname !== URLS.VERIFY) {
    const url = redirectExclusionUrls.includes(location.pathname)
      ? URLS.HOME
      : location.pathname
    history.push(url)
  }
}

export const getDiaryBorderStyle = (type) => {
  let style = { borderLeft: "6px solid #F0F0F0" }

  if (!type || typeof type !== "string") {
    return style
  }

  const _type = type.toLowerCase()
  switch (_type) {
    case FeaturesTypes.COMMUNICATION: {
      style = { borderLeft: "6px solid #FFE753" }
      break
    }
    case FeaturesTypes.LEAD: {
      style = { borderLeft: "6px solid #FF9230" }
      break
    }
    case FeaturesTypes.CONTACT: {
      style = { borderLeft: "6px solid #28B6B6" }
      break
    }
    case FeaturesTypes.COMPANY: {
      style = { borderLeft: "6px solid #009EEF" }
      break
    }
    case FeaturesTypes.FILES: {
      style = { borderLeft: "6px solid #F25041" }
      break
    }
    case FeaturesTypes.LINK: {
      style = { borderLeft: "6px solid #F25041" }
      break
    }
    case FeaturesTypes.NOTE: {
      style = { borderLeft: "6px solid #C776FF" }
      break
    }
    case FeaturesTypes.workspace: {
      style = { borderLeft: "6px solid #A9A9A9" }
      break
    }
    case FeaturesTypes.TODO: {
      style = { borderLeft: "6px solid #F25040" }
      break
    }
    case FeaturesTypes.TODO_DONE: {
      style = { borderLeft: "6px solid #3CCC7A" }
      break
    }
    default: {
      break
    }
  }
  return style
}

export const getBadgeIcon = (type) => {
  if (!type || typeof type !== "string") {
    return ""
  }
  const _type = type.toLowerCase()
  switch (_type) {
    case FeaturesTypes.COMMUNICATION: {
      return <CommentOutlined className="badge-icon communication-badge" />
    }
    case FeaturesTypes.LEAD: {
      return <JobIcon className="badge-icon" />
    }
    case FeaturesTypes.CONTACT: {
      return <UserOutlined className="badge-icon" />
    }
    case FeaturesTypes.COMPANY: {
      return <CompanyIcon className="badge-icon" />
    }
    case FeaturesTypes.FILES: {
      return <PaperClipOutlined className="badge-icon" />
    }
    case FeaturesTypes.LINK: {
      return <LinkOutlined className="badge-icon" />
    }
    case FeaturesTypes.NOTE: {
      return <NoteIcon className="badge-icon" />
    }
    case FeaturesTypes.workspace: {
      return <BookOutlined style={{ color: "dimgray", marginRight: 3 }} />
    }
    case FeaturesTypes.TODO: {
      return <TodoIcon className="badge-icon" />
    }
    case FeaturesTypes.TODO_DONE: {
      return <TodoDoneIcon className="badge-icon" />
    }
    default: {
      return null
    }
  }
}

export const getBadgeStyle = (type) => {
  let style = { backgroundColor: "#F25041" }

  if (!type || typeof type !== "string") {
    return style
  }

  const _type = type.toLowerCase()
  switch (_type) {
    case FeaturesTypes.COMMUNICATION: {
      style = { backgroundColor: "#FFE753", color: "rgba(0, 0, 0, 1)" }
      break
    }
    case FeaturesTypes.LEAD: {
      style = { backgroundColor: "#FF9230" }
      break
    }
    case FeaturesTypes.CONTACT: {
      style = { backgroundColor: "#28B6B6" }
      break
    }
    case FeaturesTypes.COMPANY: {
      style = { backgroundColor: "#009EEF" }
      break
    }
    case FeaturesTypes.FILES: {
      style = { backgroundColor: "#6B2FCE" }
      break
    }
    case FeaturesTypes.LINK: {
      style = { backgroundColor: "#6B2FCE" }
      break
    }
    case FeaturesTypes.NOTE: {
      style = { backgroundColor: "#C776FF" }
      break
    }
    case FeaturesTypes.workspace: {
      style = { backgroundColor: "#A9A9A9" }
      break
    }
    case FeaturesTypes.TODO: {
      style = { backgroundColor: "#F25040" }
      break
    }
    case FeaturesTypes.TODO_DONE: {
      style = { backgroundColor: "#3CCC7A" }
      break
    }
    default: {
      break
    }
  }
  return style
}

export const removeTagFromTables = (tags, tag) => {
  return [...(tags || [])].filter((item) => item.tag !== tag)
}

export const dragItem = (list, startIndex, endIndex) => {
  const startIndexIsNotPassed = startIndex === null || startIndex === undefined
  const endIndexIsNotPassed = endIndex === null || endIndex === undefined
  if (!list || endIndexIsNotPassed || startIndexIsNotPassed) {
    return []
  }
  let result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const sortByDate = (array, key = "create_dt") => {
  if (!array || !Array.isArray(array) || !key) {
    return []
  }
  return [...array].sort((a, b) => {
    return new Date(b[key]) - new Date(a[key])
  })
}

export const canRedirect = (profile) => {
  return Boolean(
    profile.has_verified_email &&
      profile.profile.tz &&
      profile.username &&
      isEmpty(profile?.profile?.redirect_profile?.message)
  )
}

export const firstLetterToUpperCase = (str) => {
  if (!str || typeof str !== "string") {
    return ""
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const cutStringIfLarge = (str, length = 100, insertDots) => {
  if (!str || typeof str !== "string") {
    return ""
  }
  let result = ""
  if (str.length <= length) {
    return str
  }
  result = str.substring(0, length)
  if (insertDots) {
    return `${result}...`
  }
  return result
}

export const getNameLead = (sybType) => {
  const type = sybType ? sybType : SEARCH_MODE
  return nameLead[type]
}

export const renderJobStatus = (subtype, status) => {
  const { applied, done } = getNameLead(subtype)
  if (status === JOB_APPLIED) {
    return applied
  }

  if (status === JOB_DISCARDED) {
    return done
  }
  return LEAD_STATUSES.NEW
}
export const checkUrl = (url) => {
  return url.includes("http" || "https") ? url : "http://" + url
}

export const getArrayUniqueValue = (stateArray, payloadArray) => {
  const itemsMap = new Map()

  stateArray.forEach((item) => itemsMap.set(item.id, item))

  payloadArray.forEach((item) => itemsMap.set(item.id, item))

  return Array.from(itemsMap.values())
}

export const getContentLastContact = (weeks, days) => {
  if (weeks === 0 && days === 0) {
    return "0 day"
  }
  if (weeks === 0 && days > 0) {
    return `${days} day${days > 1 ? "s" : ""}`
  }
  if (weeks === 1 && days === 0) {
    return "1 week"
  }
  if (weeks === 1 && days > 0) {
    return "1+ week"
  }
  if (weeks > 1 && days === 0) {
    return `${weeks} weeks`
  }
  if (weeks > 1 && days > 0) {
    return `${weeks}+ weeks`
  }
}

export const focusOnLastModal = () => {
  const modalContents = document.querySelectorAll(".ant-modal-body")
  const lastModal = modalContents[modalContents.length - 1]
  if (lastModal) {
    lastModal.tabIndex = 1
    lastModal.focus()
  }
  return modalContents
}
