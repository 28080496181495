import React from "react"
import { Badge, Button, Col, Radio, Row, Space, Typography } from "antd"
import { DeleteLead } from "../components"
import { PlusOutlined } from "@ant-design/icons"
import "../styles/leads.css"
import {
  JOB_APPLIED,
  JOB_DISCARDED,
  JOB_NOT_APPLIED,
} from "../common/constants"
import { LeadTable } from "../components/leads/LeadTable"
import { useLeadsTable } from "../hooks/useLeadsTable"
import withMemo from "../store/HOC/with_memo"
import { useTags } from "../hooks/useTags"

const { Title, Text } = Typography

export const Leads = withMemo(() => {
  useTags()
  const {
    jobFilter,
    setIsEdit,
    openDeleteModal,
    setOpenDeleteModal,
    contextHolder,
    jobsData,
    _new,
    applied,
    isPending,
    status,
    setStatus,
    onDiscard,
    closeDeleteModal,
    onDeleteLead,
    openAddLeadModal,
    handleUpdateFilters,
    handleClick,
    subType,
    status_id,
    allJobs,
    leadConfig
  } = useLeadsTable()
  return (
    <>
      {contextHolder}
      <Row className="contact-container lead">
        <Col
          span={24}
          style={{
            display: "flex",
            padding: "0px 8px",
            marginBottom: "16px",
            justifyContent: "space-between",
          }}
        >
          <Title className="contact-title lead" level={4}>
            {leadConfig.plural}
          </Title>

          {allJobs.length > 0 && <Radio.Group
            value={jobFilter}
            defaultValue={JOB_NOT_APPLIED}
            buttonStyle="solid"
            onChange={handleUpdateFilters}
          >
            <Radio.Button
              value={JOB_NOT_APPLIED}
              style={{ borderRadius: "6px 0px 0px 6px" }}
            >
              New
            </Radio.Button>
            <Radio.Button value={JOB_APPLIED}>{leadConfig.applied}</Radio.Button>
            <Radio.Button
              value={JOB_DISCARDED}
              style={{ borderRadius: "0px 6px 6px 0px" }}
            >
              {leadConfig.done}
            </Radio.Button>
          </Radio.Group>}


          <Space>
            <Space>
              <Text className="contact-subtitle">New</Text>
              <Badge
                style={{ marginRight: "16px", backgroundColor: "#009EEF" }}
                showZero
                count={_new}
              />
            </Space>
            <Space>
              <Text className="contact-subtitle">{leadConfig.applied}</Text>
              <Badge
                style={{ marginRight: "16px", backgroundColor: "#3CCC7A" }}
                showZero
                count={applied}
              />
            </Space>
            <Button
              type="primary"
              size={"default"}
              onClick={openAddLeadModal}
            >
              <PlusOutlined />
              New
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <LeadTable
            setStatus={setStatus}
            isPending={isPending}
            jobsData={jobsData}
            openDeleteModal={openDeleteModal}
            setIsEdit={setIsEdit}
            onDiscard={onDiscard}
            setOpenDeleteModal={setOpenDeleteModal}
            handleClick={handleClick}
            subType={subType}
            status_id={status_id}
            allJobs={allJobs}
          />
        </Col>
        {openDeleteModal && (
          <DeleteLead
            visible={openDeleteModal}
            closeModal={closeDeleteModal}
            onDelete={onDeleteLead}
            onDiscard={onDiscard}
            status={status}
          />
        )}
      </Row>
    </>
  )
})
