export const SET_CONTACTS_LIST = "SET_CONTACTS_LIST"
export const CREATE_CONTACT = "CREATE_CONTACT"
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS"
export const DELETE_CONTACT = "DELETE_CONTACT"
export const HANDLE_COMPANY_SEARCH = "HANDLE_COMPANY_SEARCH"
export const SET_SPECIFIC_CONTACT = "SET_SPECIFIC_CONTACT"
export const SET_NEW_CONTACT_TODO = "SET_NEW_CONTACT_TODO"
export const SET_CONTACT_TODOS = "SET_CONTACT_TODOS"
export const SET_CONTACT_TO_LIST = "SET_CONTACT_TO_LIST"
export const SET_CONTACTS_PENDING_STATUS = "SET_CONTACTS_PENDING_STATUS"
export const SET_CONTACTS_PAGINATION = "SET_CONTACTS_PAGINATION"
export const SET_CONTACT_FETCHING = "SET_CONTACT_FETCHING"
export const CLEAR_CONTACT_FETCHING = "CLEAR_CONTACT_FETCHING"
export const SET_DRAFT_TAGS_CONTACT = "SET_DRAFT_TAGS_CONTACT"
export const SET_COUNTERS = "SET_COUNTERS"
export const RESET_CONTACTS = "RESET_CONTACTS"
export const SET_SORT_KEY_CONTACTS = "SET_SORT_KEY_CONTACTS"
export const HANDLE_CONTACT_SEARCH = "HANDLE_CONTACT_SEARCH"
export const SET_CONTACT_SEARCH_LOADING = "SET_CONTACT_SEARCH_LOADING"
