import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getSearchPendingStatus,
  getSearchResults,
} from "../store/selectors/search/get-search-results"
import SearchResultItem from "../components/search-result-item"
import { getUserSubscriptionTypeSelector } from "../store/selectors"
import { Alert, Button, Typography } from "antd"
import { URLS } from "../common/urls"
import { getNameLead } from "../common/helpers"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { resetSearchPage } from "../store/actions/search/get-search"
import withMemo from "../store/HOC/with_memo"
import { Loader } from "../components/loaders/loader"
import { LeftOutlined } from "@ant-design/icons"
import { useActivityItem } from "../hooks/useActivityItem"

const { Title } = Typography

const SearchResults = () => {
  const results = useSelector(getSearchResults)
  const dispatch = useDispatch()
  const is_pending = useSelector(getSearchPendingStatus)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const leadInfo = getNameLead(subType)
  const { handleClick } = useActivityItem()
  const hasData = useCallback(() => {
    let res = false
    for (const key in results) {
      if (results?.hasOwnProperty(key) && !isEmpty(results[key])) {
        res = true
        break
      }
    }
    return !is_pending && res
  }, [results, is_pending])
  const openModal = useCallback(
    (type, value) => {
      handleClick(
        {
          currentTarget: {
            dataset: {
              id: value.id,
            },
          },
        },
        type,
        value
      )
    },
    [handleClick]
  )
  const history = useHistory()
  useEffect(() => {
    return () => {
      dispatch(resetSearchPage())
    }
  }, [dispatch])
  return (
    <>
      {is_pending && <Loader />}
      {!hasData() && <Alert message="No results found" type="warning" />}
      <div
        id={"search_results"}
        style={{
          margin: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "8px",
            alignItems: "center",
          }}
        >
          <Title level={4} style={{ marginBottom: "0px" }}>
            Search Results
          </Title>
          <Button
            onClick={() => history.push(URLS.HOME)}
            size="small"
            style={{
              borderRadius: "6px",
              color: "#009EEF",
              borderColor: "#009EEF",
            }}
          >
            <LeftOutlined /> Back
          </Button>
        </div>
        <div
          className="search-results"
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          {Object.values(results).map((item, i) => (
            <div
              className="result"
              style={{ marginRight: "14px", width: "100%", maxWidth: "272px" }}
              key={i}
            >
              <SearchResultItem
                title={Object.keys(results)[i]}
                item={item}
                openModal={openModal}
                leadInfo={leadInfo}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default withMemo(SearchResults)
