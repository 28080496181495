import React, { useRef, useState, useCallback } from "react"
import {
  AutoComplete,
  Button,
  Col,
  Input,
  Popover,
  Table,
  Tooltip,
  Typography,
  ConfigProvider,
  Empty,
  Popconfirm
} from "antd"
import OutsideClickHandler from "react-outside-click-handler"
import { useDispatch } from "react-redux"
import AddTag from "../add_tag"
import { EditOutlined, LinkOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { LinkItem } from "../notes/link_item"
import { editCompany } from "../../store/actions/companies/edit-company"
import { setSpecificCompany } from "../../store/actions/companies/get-companies"
import { removeCompanyFromActivity, removeCompany } from "../../store/actions/companies/remove-company"
import { resetModals } from "../../store/actions/ui/update_ui"
import { FeaturesTypes } from "../../common/constants"
import { focusOnLastModal } from '../../common/helpers'
import NoData from "../../assets/images/no_data_contact.svg"
import { isEmpty } from "lodash"
import { MiniSpinner } from "../loaders/spinner"

const { Option } = AutoComplete

const { Title } = Typography

export const CompanyDetails = ({
  contacts_pending,
  isAdd,
  handleSubmit,
  handleSearchNames,
  data,
  confirmModal,
  setData,
  companiesNames,
  handleOpenCompanyEdit,
  specificCompany,
  setAddTagStep,
  inputRef,
  addTagStep,
  contactsColumns,
  contacts,
  handleOpenContactModal,
  showError,
  setShowError,
  companyNameRef,
  handleClose,
  handleClick,
  handleClickSearchCompany,
  isSearchCompanyLoading
}) => {
  const dispatch = useDispatch()
  const linkInputEl = useRef(null)
  const [popOverVisible, handleVisibleChange] = useState(false)
  const [urlIsEdit, setUrlIsEdit] = useState(false)

  const handleChangeUrl = (e) => {
    setUrlIsEdit(true)
    setData({ ...data, url: e.currentTarget.value })
  }

  const handleRemove = useCallback(() => {
    if (isEmpty(specificCompany)) {
      return
    }
    handleClose()
    dispatch(setSpecificCompany({}))
    dispatch(removeCompanyFromActivity(specificCompany.id))
    dispatch(resetModals())
    dispatch(removeCompany(specificCompany.id))

  }, [specificCompany, dispatch, handleClose])


  const hide = () => {
    handleVisibleChange(false)
  }

  const unhide = () => {
    if (data.url) setUrlIsEdit(false)
    handleVisibleChange(true)
  }

  const handleOutsideClick = (e) => {
    if (
      e.target.innerText === "Cancel" ||
      e.target.innerText === "Clear link ?"
    )
      return
    hide()
  }

  const settings = {
    bottom: "bottomRight",
    hasData: true,
    pagination: false,
    showHeader: true,
    size: "small",
    tableLayout: "fixed",
    title: undefined,
    top: "none",
  }


  return (
    <>
      <Col span={9} style={{ paddingRight: "24px" }}>
        <Title className="contact-modal title name" level={5}>
          Company
        </Title>
        {showError && !data.name && (
          <div style={{ color: "red" }}>Please enter a company name</div>
        )}
        <div style={{ display: "flex", position: 'relative' }}>

          {isSearchCompanyLoading && <div style={{ position: 'absolute', right: "12%", top: '10%', zIndex: 10 }}>
            <MiniSpinner /></div>}

          <AutoComplete
            style={{
              width: "100%",
            }}
            ref={companyNameRef}
            onSearch={handleSearchNames}
            defaultValue={data.name}
            value={data.name}
            onSelect={e => {
              handleClickSearchCompany(e)
            }}
            onChange={(name) => {
              setShowError(true)
              if (!confirmModal) {
                setData({ ...data, name })
              }
            }}
          >
            {data?.name?.trim() && companiesNames.map((company) => (
              <Option
                // onClick={() => {
                //   handleOpenCompanyEdit(company)
                // }}
                value={company.name}
                key={company.id}
              >
                {company.name}
              </Option>
            ))}
          </AutoComplete>

          <div>
            <Popover
              content={
                <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                  {!data.url || urlIsEdit
                    ?
                    <Input
                      tabIndex="-1"
                      autoFocus
                      ref={linkInputEl}
                      onKeyDown={(e) => {
                        e.stopPropagation()
                        if (e.key === "Escape") {
                          hide()
                          focusOnLastModal()
                        }
                      }}
                      value={data.url}
                      onChange={handleChangeUrl}
                      onPressEnter={hide}
                      className="contact-modal inpunt share-inpunt"
                      allowClear
                      placeholder="Link"
                    />
                    :
                    <>
                      <LinkItem link={data.url} file />
                      <EditOutlined
                        className="lead-modal description edit-icon"
                        onClick={() => {
                          setUrlIsEdit(p => !p)
                        }}
                        style={{ marginRight: 5 }}
                      />
                      <Popconfirm
                        placement="right"
                        className="delete_file"
                        title="Clear link ?"
                        onConfirm={() => {
                          hide()
                          setTimeout(() => setData({ ...data, url: "" }), 500)
                        }}
                        okText="Clear"
                        cancelText="Cancel"
                      >
                        <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                      </Popconfirm>
                    </>
                  }

                </OutsideClickHandler>
              }
              trigger="click"
              open={popOverVisible}
              onOpenChange={(visible) => {
                if (visible && !data.url) {
                  setTimeout(() => {
                    linkInputEl.current?.focus()
                  }, 200)
                }
              }}
            >

              <Tooltip placement="bottom" title="Click to attach the link">
                <div
                  style={{ padding: '5px', marginLeft: '10px' }}
                >
                  <LinkOutlined
                    style={{
                      color: !data.url ? "#8c8c8c" : "#009EEF",
                      fontSize: '20px'
                    }}
                    onClick={unhide} />
                </div>
              </Tooltip>

            </Popover>
          </div>
        </div>
        <Title className="contact-modal title" level={5}>
          Location
        </Title>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "11px" }}>
            <Input
              className="contact-modal inpunt small phone"
              placeholder="Street"
              value={data.city}
              onChange={(e) => {
                setData({ ...data, city: e.target.value })
              }}
            />
          </div>
          <div>
            <Input
              value={data.state}
              onChange={(e) => {
                setData({ ...data, state: e.target.value })
              }}
              className="contact-modal inpunt small"
              placeholder="City, State, ZIP"
            />
          </div>
        </div>
        <Title className="contact-modal title" level={5}>
          Notes
        </Title>
        <Input.TextArea
          value={data?.notes}
          onChange={(e) => {
            setData({ ...data, notes: e.currentTarget.value })
          }}
          style={{ minHeight: "115px" }}
        />
        <div
          className="d-flex space-between w-100 position-relative"
          style={{ alignItems: "center", marginBottom: "1rem" }}
        >
          <AddTag
            item={specificCompany}
            addTagStep={addTagStep}
            inputRef={inputRef}
            setAddTagStep={setAddTagStep}
            update={editCompany}
            type={FeaturesTypes.COMPANY}
          />
        </div>
        <div style={{ height: "32px" }}>
          <Button
            onClick={() => {
              setShowError(true)
              handleSubmit()
            }}
            style={{ position: "absolute", bottom: "0" }}
            type="primary"
          >
            Done
          </Button>
        </div>
        {!isAdd && (

          <Popconfirm
            placement="topRight"
            className="delete_file"
            title="Delete company? There is no undo."
            onConfirm={handleRemove}
            okText="Delete"
            cancelText="Cancel">
            <Button

              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                marginRight: "24px",
              }}
              type="primary"
              danger
            >
              Delete
            </Button>
          </Popconfirm>

        )}
      </Col>
      <Col span={15}>
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            marginTop: "24px",
            alignItems: "center",
          }}
        >
          <Title className="company-modal contact" level={5}>
            Contact
          </Title>
          <Button
            type="primary"
            size={"small"}
            style={{ marginLeft: "auto", marginTop: "4px" }}
            onClick={() => {
              handleOpenContactModal()
            }}
          >
            <PlusOutlined />
            New
          </Button>
        </div>
        <div className="contact-modal table">
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                className="no-data"
                image={
                  <img src={NoData} alt="no-data" className="img-no-data" />
                }
                description={<span style={{ color: "rgb(181, 179, 179)" }}>No Contacts</span>}
              />
            )}
          >
            <Table
              {...settings}
              rowKey={(row) => row?.id}
              dataSource={contacts.filter((item) => item.company?.id === specificCompany?.id)}
              columns={contactsColumns}
              size="small"
              scroll={{ y: "400px" }}
              loading={contacts_pending}
              rowClassName={record => record.disable && "disabled-row"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    handleClick(record)
                  },
                }
              }}
            />
          </ConfigProvider>
        </div>
      </Col>
    </>
  )
}
