import React, { useCallback, useMemo } from "react"
import { Popover, Table, ConfigProvider, Empty, Spin } from "antd"
import moment from "moment"
import { DATE_FORMAT } from "../../common/config"
import { TagsList } from "../tags/TagsList"
import JobsStatusSwitcher from './apply-tab'
import { DeleteOutlined, EditOutlined, LinkOutlined, CheckOutlined } from "@ant-design/icons"
import { FeaturesTypes, JOB_APPLIED, JOB_DISCARDED, JOB_NOT_APPLIED, CRM_MODE } from "../../common/constants"
import { getContentLastContact, getNameLead } from "../../common/helpers"
import { useColors } from "../../hooks/useColors"
import NoNewLeadsIcon from "../../assets/images/NewLeads.svg"
import NoAppliedLeadsIcon from "../../assets/images/AppliedLeads.svg"
import EmptyIcon from "../../assets/images/no_data.svg"
import TitlePopup from "../TitlePopup"

export const LeadTable = ({
  company_id,
  setStatus,
  isPending,
  setOpenDeleteModal,
  openDeleteModal,
  onDiscard,
  jobsData,
  handleClick,
  subType,
  status_id,
  allJobs
}) => {
  const { allColors } = useColors()
  const leadInfo = getNameLead(subType)
  const jobsColumns = [
    {
      title: leadInfo.name,
      key: "job",
      width: "40%",
      render: ({ company, title, url }) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <TitlePopup title={`${company?.name ? company.name : ""}${company?.name && title ? ", " : ""} ${title ? title : ""}`} maxLength={80} />
          {url &&
            <a href={url?.includes("http") ? url : 'https://' + url} target="_blank" rel="noopener noreferrer">

              <LinkOutlined width={20}
                style={
                  {
                    marginTop: "2px",
                    color: "#009EEF",
                    fontSize: '17px',
                    cursor: "pointer"
                  }} />

            </a>
          }
        </div>
      },
      sorter: (a, b) => {
        return a?.company?.name
          ?.toLowerCase()
          .localeCompare(b?.company?.name?.toLowerCase())
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "21.2%",
      sorter: (a, b) => {
        return a?.location
          ?.toLowerCase()
          .localeCompare(b?.location?.toLowerCase())
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Added",
      dataIndex: "create_dt",
      key: "create_dt",
      width: "8.2%",
      render: (date) => {
        return <span>{moment(new Date(date)).format(DATE_FORMAT)}</span>
      },
      sorter: (val) => {
        return -new Date(val.create_dt).getTime()
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Last contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "10%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7);
        const days = totalDays % 7;
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: (a, b) => {
        return a?.seasoning - b?.seasoning
      },
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Status",
    //   dataIndex: "status_id",
    //   key: "status_id",
    //   width: "5%",
    //   align: "center",
    //   render: (status, row) => {
    //     return <span>{renderJobStatus(status, row.id)}</span>
    //   },
    // },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
      sorter: (a, b) => {
        return a?.tags?.length - b?.tags?.length
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status_id",
      width: "10%",
      render: (status, lead) => {
        return <JobsStatusSwitcher job={lead} />
      },
    },
    {
      title: "Actions",
      width: "7%",
      render: (record, row) => {
        return (
          <div>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation()
                onEditLead(row)
              }}
              style={{ marginRight: "1rem" }}
            />
            <Popover
              content={
                row.status_id !== JOB_DISCARDED
                  ? `When pressed, the ${subType === CRM_MODE ? "lead will be completed" : "job will be discarded"}`
                  : "When pressed, the job will be deleted"
              }
            >
              {row.status_id === JOB_DISCARDED ? <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDelete(row)
                }}
                style={{ color: "#F25041" }}

              /> : subType === "CRM" ? <CheckOutlined onClick={(e) => {
                e.stopPropagation()
                toggleDelete(row)
              }} /> : <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDelete(row)
                }}
                style={{ color: "#F25041" }} />}

            </Popover>
          </div>
        )
      },
    },
  ]
  const toggleDelete = async (row) => {
    setStatus({ id: row.id, status_id: row.status_id })
    if (row.status_id === JOB_DISCARDED) {
      setOpenDeleteModal(!openDeleteModal)
    } else {
      await onDiscard(row)
    }
  }
  const onEditLead = (lead) => {
    handleClick(lead, FeaturesTypes.LEAD, lead)
  }

  const renderEmptyText = useCallback(() => {
    if (allJobs.some(e => e.status_id === JOB_NOT_APPLIED)) {
      return `No ${leadInfo.plural}.`
    } else if (status_id === JOB_NOT_APPLIED) {
      return `This is where you track new ${leadInfo.plural.toLowerCase()}. ${leadInfo.plural} to apply to, engagements to explore, deals to pursue. Every ${leadInfo.name.toLowerCase()} matters.`
    } else if (status_id === JOB_APPLIED) {
      return `Tracking ${leadInfo.plural.toLowerCase()} where you’ve taken the first step. What’s the latest? What are the next steps? Don’t lose track, stay on top of your active bucket.`
    } else {
      return `No ${leadInfo.done.toLowerCase()} ${leadInfo.plural.toLowerCase()} yet.`
    }
  }, [status_id, allJobs, leadInfo])

  const checkHasNewLeads = useCallback(() => {
    return allJobs.some(e => e.status_id === JOB_NOT_APPLIED)
  }, [allJobs])

  const settings = useMemo(
    () => ({
      bottom: "bottomRight",
      hasData: true,
      pagination: false,
      showHeader: true,
      size: "small",
      tableLayout: "fixed",
      title: undefined,
      top: "none",
    }),
    []
  )
  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          className="app-empty"
          style={{ opacity: isPending ? 0.5 : 1, padding: "30px", position: "relative" }}
          image={<img
            src={checkHasNewLeads() ? EmptyIcon : status_id === JOB_NOT_APPLIED ? NoNewLeadsIcon : NoAppliedLeadsIcon}
            alt="empty-lead-icon"
            className={checkHasNewLeads() ? "" : "home-empty-image"}
          />}
          description={
            <span
              style={{
                fontSize: checkHasNewLeads() ? 14 : 16,
                color: checkHasNewLeads() ? "grey" : "black"
              }}
            >
              {renderEmptyText()}

            </span>
          }
        />
      )}
    >
      <Spin spinning={isPending} style={{
        position: "absolute", zIndex: 1, left: "50%", top: "50%"
      }} />
      <Table
        {...settings}
        className={jobsData.length > 0 ? "" : "table-empty"}
        showHeader={jobsData.length > 0}
        scroll={{ y: "510px" }}
        rowKey={(row) => row.id}
        columns={jobsColumns}
        dataSource={jobsData}
        index={"jobs"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              e.stopPropagation()
              onEditLead(record)
            },
          }
        }}
      />
    </ConfigProvider>
  )
}
