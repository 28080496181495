/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Text from "antd/es/typography/Text"
import moment from "moment"
import { DATE_FORMAT } from "../../common/config"
import withMemo from "../../store/HOC/with_memo"
import Icon, { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { FeaturesTypes } from "../../common/constants"
import { QuilEditor } from "../editor/quil-editor"
import { useAction } from "../../hooks/useAction"
import { editCommunicationInDiary } from "../../store/actions/diaries/edit-diary"
import { fetchContactByIdIfNeeded } from "../../store/actions/contacts/get-contacts-list"
import { fetchJobByIdIfNeeded, clearJobFetching } from "../../store/actions/jobs/get-jobs"
import { setCommunicationEditStatus } from "../../store/actions/communication/update-communication"
import { TagsList } from "../tags/TagsList"
import { Button } from "antd"
import Spinner from "../loaders/spinner"
import {
  clearContactFetching
} from "../../store/actions/contacts/get-contacts-list"
import { getContactsSelector, getJobsSelector } from "../../store/selectors";
import { debounce } from "lodash"



const Note = ({
  body,
  item,
  handleClick,
  itemKey,
  content,
  isLarge,
  handleExpandNote,
  currentIsExpanded,
  diary_date,
  setDrawerIsEditing,
  allColors,
}) => {
  const [currentEditing, setCurrentEditing] = useState(0)
  const [text, setText] = useState("")
  const [loading, setLoading] = useState(false)
  const [showQuillChild, setShowQuillChild] = useState(false)
  const [isCommunication] = useState(itemKey === FeaturesTypes.COMMUNICATION)
  const [isNote] = useState(itemKey === FeaturesTypes.NOTE)
  const [data, setData] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const editorRef = useRef()
  const dispatch = useDispatch()


  const changeCommunicationAC = useAction(editCommunicationInDiary)
  const setCommunicationStatus = useAction(setCommunicationEditStatus)
  const allContacts = useSelector(getContactsSelector)
  const allLeads = useSelector(getJobsSelector)




  const onEditLead = (lead) => {
    handleClick(lead, FeaturesTypes.LEAD, lead)
  }
  const handleEditContactTools = (contact) => {
    handleClick(contact, FeaturesTypes.CONTACT, contact)
  }

  const handleClickClose = (e) => {
    e.stopPropagation()
    if (isEditing) {
      setCurrentEditing(0)
      setText("")
      setDrawerIsEditing(false)
      setIsEditing(false)
      setCommunicationStatus(false)
      return
    }
    setDrawerIsEditing(true)
    setCommunicationStatus(true)
    setIsEditing(true)
  }

  const handleClickEdit = useCallback(
    (e) => {
      e.stopPropagation()
      if (isEditing) {
        changeCommunicationAC({ ...data, id: item.id }, diary_date)
        setCurrentEditing(0)
        setText("")
        setDrawerIsEditing(false)
        setIsEditing(false)
        setCommunicationStatus(false)
        return
      }
      setData(item)
      setCurrentEditing(item.id)
      setText(item.html_body || item.body)
      setDrawerIsEditing(true)
      setCommunicationStatus(true)
      setIsEditing(true)
    },
    [
      changeCommunicationAC,
      data,
      diary_date,
      isEditing,
      item,
      setCommunicationStatus,
      setDrawerIsEditing,
    ]
  )

  const actionAfterTyping = (d) => {

    if (d.id) {
      setLoading(true)
      changeCommunicationAC(d, diary_date).finally(() => setLoading(false))
    }
  }



  const debouncedAction = useCallback(debounce(actionAfterTyping, 1000), [])

  useEffect(() => {
    return () => debouncedAction.cancel()
  })
  const handleChange = useCallback(
    (html_body, deltaObj, source, editor) => {
      if (editorRef?.current?.focus) {
        editorRef.current.focus()
      }
      setText(editor.getContents())
      setData({
        ...data,
        html_body,
        body_editor: editor.getContents(),
        body: editor.getText(),
      })
      if (source === 'user') {
        debouncedAction({
          ...data,
          id: item.id,
          html_body,
          body_editor: editor.getContents(),
          body: editor.getText(),
        })
      }
    },
    [data]
  )
  const handleClickOnText = (e) => {
    if (isCommunication && !isEditing) {
      handleClickEdit(e)
      return
    }
    if (isEditing) {
      return
    }
  }

  const DiaryEditSvg = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0932 1.79298L10.0163 0.715577C9.99419 0.694008 9.9793 0.666277 9.97211 0.635978C9.96544 0.605679 9.96698 0.574353 9.97725 0.545081C9.987 0.515809 10.0055 0.490132 10.0291 0.470618C10.0533 0.451103 10.0825 0.438778 10.1133 0.435183L13.8149 0.00175591C13.9202 -0.010569 14.0106 0.0782732 13.9983 0.185603L13.5648 3.8872C13.5484 4.02328 13.3815 4.0808 13.2844 3.98426L12.2034 2.90274L6.91707 8.18912C6.85287 8.25331 6.74811 8.25331 6.68392 8.18912L5.80885 7.31405C5.74517 7.25037 5.74517 7.14509 5.80885 7.0809L11.0932 1.79298Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M13.4399 14H0.560122C0.250443 14 0 13.75 0 13.4403V0.560122C0 0.250443 0.250443 0 0.560122 0H6.85997C6.93706 0 7.00022 0.0631542 7.00022 0.140247V1.12024C7.00022 1.19734 6.93706 1.26006 6.85997 1.26006H1.26006V12.7404H12.7399V7.14003C12.7399 7.06337 12.8031 7.00022 12.8802 7.00022H13.8602C13.9368 7.00022 14 7.06337 14 7.14003V13.4403C14 13.75 13.75 14 13.4399 14Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  )

  const CloseIconSvg = () => (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L12 12"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="1.5"
      />
      <path
        d="M12 1L1 12"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="1.5"
      />
    </svg>
  )



  useEffect(() => {
    item.contact &&
      dispatch(fetchContactByIdIfNeeded(item.contact));
    item.job &&
      dispatch(fetchJobByIdIfNeeded(item.job));
    return () => {
      item.contact &&
        dispatch(clearContactFetching(item.contact))
      item.job &&
        dispatch(clearJobFetching(item.job));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAdditional = useCallback(() => {
    let text = ""
    if (item?.job) {
      const job = allLeads.find(l => l.id === item.job)
      text =
        (job?.title || "")
        +
        (job?.company?.name ? " @ " + job?.company?.name : "")
    } else if (item?.contact) {
      const contact = allContacts.find(c => c.id === item.contact)
      text =
        (contact?.first_name || "")
        +
        (contact?.company?.name ? " @ " + contact?.company?.name : "")

    }

    return text?.trim() ? <>{text} <br /></> : ""
  }, [allLeads, allContacts, item?.job, item?.contact])

  const MyButtonClose = () => {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.keyCode === 27) {
          handleClickClose(event)
          document.querySelector(".ant-drawer").focus()
        }
      }

      document.addEventListener('keyup', handleEsc)
      return () => {
        document.removeEventListener('keyup', handleEsc)
      }
    }, [])
    return (
      <Button
        style={{ margin: 0 }}
        className="diary-edit-btn close diary_edit_button_close"
        onClick={handleClickClose}
      >
        <Icon component={CloseIconSvg} />
      </Button>
    )
  }

  return (
    <div>


      <div
        id={item.id}
        data-id={item.id}
        className="d-flex flex-column cursor-pointer"
        onClick={handleClickOnText}
      >
        {isCommunication ? (
          <>
            {isEditing ? (
              <>
                <div className="edit-comm-header al-it_c df">
                  <span
                    onClick={() => {
                      if (item.contact) {
                        const contact = allContacts.find(l => l.id === item.contact)
                        if (contact) {
                          handleEditContactTools(contact)
                        }

                      }

                      if (item.job) {
                        const job = allLeads.find(l => l.id === item.job)
                        if (job) {
                          onEditLead(job)
                        }

                      }
                    }}
                  >
                    {renderAdditional()}
                  </span>
                  <div className="edit-header comm">
                    {isEditing ? (

                      <MyButtonClose />

                    ) : (
                      <EditOutlined onClick={handleClickEdit} />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="edit-header">
                {isEditing ? (
                  <CheckOutlined onClick={handleClickEdit} />
                ) : (
                  <EditOutlined onClick={handleClickEdit} />
                )}
              </div>
            )}
          </>
        ) : (
          <div className="edit-header">
            <Icon
              component={DiaryEditSvg}
              style={{ marginLeft: "8px" }}
              onClick={(e) => {
                handleClick(e, itemKey, item)
              }}
            />
          </div>
        )}

        <div
          className={isEditing ? "d-flex" : ""}
          style={
            isEditing
              ? { textAlign: "start" }
              : { textAlign: "start", maxWidth: "492px" }
          }
        >
          {isNote && item.title && (
            <strong>
              {item.title} {!!body && " "}{" "}
            </strong>
          )}
          {currentEditing === item.id && isEditing ? (
            <div className="diary-quill-editor" style={{ position: "relative", width: "100%" }}>
              <div style={{ position: "absolute", top: "2%", right: "5%" }}> {loading && <Spinner />}</div>
              <QuilEditor
                is_diary={isCommunication}
                editorRef={editorRef}
                content={text}
                focus
                onChange={handleChange}
                showChild={showQuillChild}
                setShowChild={setShowQuillChild}
              />
            </div>
          ) : (
            <>
              {isCommunication &&
                !(currentEditing === item.id && isEditing) && (
                  <>
                    <div className="df edit-comm-date">
                      <div style={{ width: "350px" }}>
                        <div>{renderAdditional()}</div>


                      </div>

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Text className="diary_drawer_date">
                          Added: {moment(item.create_dt).format(DATE_FORMAT)}
                        </Text>
                      </div>
                    </div>
                    <div>
                      {content}
                      {!isEditing && isLarge(item, itemKey) && (
                        <span
                          data-config={JSON.stringify(item)}
                          onClick={handleExpandNote}
                          className="link"
                        >
                          {currentIsExpanded ? " Show Less" : " See More"}
                        </span>
                      )}
                    </div>
                  </>
                )}
            </>
          )}
        </div>
        {currentEditing === item.id && isEditing && (
          <>
            <div
              className={
                !(isCommunication && currentEditing === item.id && isEditing)
                  ? "largeNotes d-flex space-between align-center"
                  : "edit-comm-footer largeNotes d-flex space-between align-center"
              }
            >
              <TagsList tags={item.tags} allColors={allColors} />
              <Text className="diary_drawer_date large-date">
                Added: {moment(item.create_dt).format(DATE_FORMAT)}
              </Text>
            </div>
          </>
        )}
      </div>
    </div >
  )
}

export default withMemo(Note)
