import React, { useEffect, useState } from 'react';
import { Image } from 'antd'
import { request } from '../api/axios_helper'



const SecureImage = ({ url }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const res = await request({ responseType: "blob" }).get(url);
                if (res?.data) {
                    const src = URL.createObjectURL(res.data);
                    setImageSrc(src);
                }
            } catch (err) {
                console.error('Error fetching the image:', err);
                setError(err);
            }
        };
        fetchImage();
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return <p>Error loading image: {error.message}</p>;
    }


    return (
        <div>
            {imageSrc ? (
                <Image src={imageSrc} alt="Bcard " width={260} height={150} />
            ) : (
                <p>Loading image...</p>
            )}
        </div>
    );
};

export default SecureImage;
