import React, { useCallback } from "react"
import { Button, Col, ConfigProvider, Empty, Table, Typography } from "antd"
import { useSelector } from "react-redux"
import {
  CompanyNotesSelector,
} from "../../store/selectors"
import { isEmpty } from "lodash"
import { PlusOutlined } from "@ant-design/icons"
import { getAntdModals } from "../../store/selectors/ui/get-ui"
import { useColors } from "../../hooks/useColors"
import { NotesHorizontalList } from "../notes/notes-horizontal-list"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import moment from "moment"
import { DATE_FORMAT } from "../../common/config"
import { renderJobStatus, getNameLead } from "../../common/helpers"
import { FeaturesTypes, CRM_MODE } from "../../common/constants"
import NoData from "../../assets/images/no_data.svg"
import NoNoteData from "../../assets/images/no_data_note.svg"

const { Title, Text } = Typography

export const CompanyTables = ({
  handleAddNewNote,
  handleSubmit,
  handleOpenCompanyDeleteModal,
  isAdd,
  jobs_is_pending,
  leads,
  specificCompany,
  handleClick,
  sybType
}) => {

  const antd_modals = useSelector(getAntdModals)
  const { allColors } = useColors()
  const notes = useSelector(CompanyNotesSelector)
  const handleOpenNoteEditor = useCallback(
    (data) => {
      handleClick(data, FeaturesTypes.NOTE, data)
    },
    [handleClick]
  )
  const leadInfo = getNameLead(sybType)
  const settings = {
    bottom: "bottomRight",
    hasData: true,
    pagination: false,
    showHeader: true,
    size: "small",
    tableLayout: "fixed",
    title: undefined,
    top: "none",
  }
  const jobsColumns = [
    {
      title: leadInfo.name,
      key: "title",
      width: "16.8%",
      render: (job) => <span>{sybType === CRM_MODE && job?.company?.name && `${job.company.name}, `}{job.title}</span>,
      sorter: (a, b) => {
        return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Added",
      dataIndex: "create_dt",
      key: "create_dt",
      width: "8.2%",
      render: (date) => {
        return <span>{moment(new Date(date)).format(DATE_FORMAT)}</span>
      },
      sorter: (val) => {
        return -new Date(val.create_dt).getTime()
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status_id",
      key: "status_id",
      width: "8.2%",
      render: (status, row) => {
        return <span>{renderJobStatus(sybType, status)}</span>
      },
    },
  ]

  return (
    <DndProvider backend={HTML5Backend}>
      <Col span={12}>
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            marginTop: "24px",
            alignItems: "center",
          }}
        >
          <Title className="company-modal contact" level={5}>
            Updates
          </Title>
          <Button
            type="primary"
            size={"small"}
            style={{ marginLeft: "auto", marginTop: "4px" }}
            onClick={handleAddNewNote}
          >
            <PlusOutlined />
            New
          </Button>
        </div>
        <div
          className="scroll-vertical custom-notes company-updates"
          style={{
            minHeight: "378px",
            paddingRight: '0px'
          }}
        >
          {isEmpty(notes) ? (
            <>
              <Empty
                className="no-data"
                image={
                  <img src={NoNoteData} alt="no-data" className="img-no-data" />
                }
                description={<span style={{ color: "rgb(181, 179, 179)" }}>No Notes</span>}
              />
            </>
          ) : (
            <NotesHorizontalList
              notes={notes}
              openEditor={handleOpenNoteEditor}
              allColors={allColors}
              disableDrag
              isDrawer
              isCompanyModal
            />
          )}
        </div>
        <div style={{ height: "32px" }}>
          <Button
            onClick={handleSubmit}
            style={{ position: "absolute", bottom: "0" }}
            type="primary"
          >
            Done
          </Button>
        </div>
        {!isAdd && (
          <div style={{ height: "32px" }}>
            <Button
              onClick={() => handleOpenCompanyDeleteModal(antd_modals)}
              style={{ position: "absolute", bottom: "0", right: "0" }}
              type="primary"
              danger
            >
              Delete
            </Button>
          </div>
        )}
      </Col>
      <Col span={12}>
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            marginTop: "24px",
            marginLeft: "44px",
          }}
        >
          <Title className="company-modal contact" level={5}>
            {leadInfo.plural}
          </Title>
        </div>
        <div
          className="company-modal notes-table"
          style={{
            marginLeft: "44px",
          }}
        >
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                className="no-data"
                image={
                  <img src={NoData} alt="no-data" className="img-no-data" />
                }
                description={<span style={{ color: "rgb(181, 179, 179)" }}>No {leadInfo.plural}</span>}
              />
            )}
          >
            <Table
              {...settings}
              columns={jobsColumns}
              size="small"
              scroll={{ y: "351px" }}
              rowKey={(item) => item.id}
              dataSource={isAdd ? [] : leads}
              loading={jobs_is_pending}
              rowClassName={record => record.disable && "disabled-row"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    const e = { currentTarget: { dataset: { id: record.id } } }
                    handleClick(e, FeaturesTypes.LEAD, record)
                  },
                }
              }}
            />
          </ConfigProvider>
        </div>
        <div
          className="lead-modal key-dates"
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            textAlign: "right",
          }}
        >
          <Text
            style={{
              marginBottom: "2px",
              display: "inherit",
              color: "rgba(0, 0, 0, 0.6)",
              lineHeight: "12px",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <span
                style={{
                  textAlign: "right",
                  marginLeft: "auto",
                }}
              >
                {specificCompany?.name}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span
                style={{
                  textAlign: "right",
                  width: "62.15px",
                  marginLeft: "auto",
                  marginRight: "6px",
                  marginBottom: "5px",
                }}
              >
                Added:{" "}
              </span>
              <span
                style={{
                  textAlign: "right",
                  minWidth: "70.68px",
                  marginLeft: "auto",
                }}
              >
                {moment(specificCompany?.create_dt).format(DATE_FORMAT)}
              </span>
            </div>

            <div style={{ display: "flex" }}>
              <span
                style={{
                  textAlign: "right",
                  width: "62.15px",
                  marginLeft: "auto",
                  marginRight: "6px",
                }}
              >
                Edited:{" "}
              </span>
              <span
                style={{
                  textAlign: "right",
                  minWidth: "70.68px",
                  marginLeft: "auto",
                }}
              >
                {moment(specificCompany?.last_edit_dt).format(DATE_FORMAT)}
              </span>
            </div>
          </Text>
        </div>
      </Col>
    </DndProvider>
  )
}
