/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react"
import Login from "./Login"
import { useDispatch, useSelector } from "react-redux"
import { authUser, setLoginBadRequestError, setAuthErrors } from "../store/actions/user"
import { Alert, Col, Row } from "antd"
import {
  getAuthErrors,
  getEmailIsConfirmed,
  getLoginBadRequestError,
} from "../store/selectors/user/get-login-status"
import { EMAIL_CONFIRMED_MESSAGE } from "../common/constants"
import { useLocation } from "react-router-dom"
import Logo from "../assets/images/logo.svg"

export const LoginContainer = () => {
  const dispatch = useDispatch()
  const errors = useSelector(getAuthErrors)
  const full_error = useSelector(getLoginBadRequestError)
  const location = useLocation()
  const email_is_confirmed = useSelector(getEmailIsConfirmed)
  const [message, setMessage] = useState(null)

  const clearErrors = useCallback(() => {
    dispatch(setAuthErrors([]))
    setMessage(null)
    full_error && dispatch(setLoginBadRequestError(null))
  }, [full_error, dispatch])

  const onSubmit = (credentials) => {
    clearErrors()
    localStorage.setItem("logged_in", 1)
    dispatch(authUser(credentials, false))
  }
  const showAlert = useCallback(() => {
    return email_is_confirmed !== null && email_is_confirmed
  }, [email_is_confirmed])

  useEffect(() => {
    clearErrors()
  }, [])


  useEffect(() => {
    const { message } = { ...(location.state || {}) }
    message && setMessage(message)
  }, [])
  return (
    <>
      <Row>
        <Col
          className="logo-cols"
        >
          <div>
            <a href="https://savvyy.com/">
              <img src={Logo} alt="" />
            </a>
          </div>
        </Col>
        <Col
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row style={{ display: "block", width: "422px" }}>
            {message && (
              <Alert
                message={message}
                className="error-alert"
                type="info"
              />
            )}
            {showAlert() && (
              <Alert
                message={EMAIL_CONFIRMED_MESSAGE}
                style={{ position: "absolute", top: "-48px", width: "422px" }}
                type="success"
              />
            )}
            <Login
              full_error={full_error}
              errors={errors}
              onSubmit={onSubmit}
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}
