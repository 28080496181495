import { useRef, useEffect, useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useActivityItem } from "./useActivityItem"
import { getSpecificJob } from "../store/selectors"
import { getSpecificContact } from "../store/selectors/contacts/get-contacts-selector"
import { getSpecificCommunication } from "../store/selectors/communications/get-communications-list"
import moment from "moment"
import { updateCommunication } from "../store/actions/communication/update-communication"
import {
  createCommunicationTH,
  created,
} from "../store/actions/communication/create-communication"
import { useAction } from "./useAction"
import { debounce } from "lodash"
import { FeaturesTypes } from "../common/constants"

export const useAddCommunication = (rest, visible) => {
  const { isEdit, onAdd, is_communication_edit, onClose } = rest
  const lead = useSelector(getSpecificJob)
  const contact = useSelector(getSpecificContact)
  const specificCommunication = useSelector(getSpecificCommunication)
  const [isChanged, setIsChanged] = useState(false)
  const [openDatePicker, setOpenDatePicker] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isRequest, setIsRequest] = useState(false)
  const [data, setData] = useState({
    comm_type: 1,
    ...specificCommunication,
  })
  const pickerRef = useRef(null)
  const inputRef = useRef(null)
  const updateCommunicationAction = useAction(updateCommunication)
  const createCommunicationAction = useAction(createCommunicationTH)
  const notifyCreatedCommunication = useAction(created)
  const radioStyle = useMemo(() => {
    return {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
    }
  }, [])

  const { handleClick } = useActivityItem()

  const handleClickIcon = async () => {
    setData({
      body: "",
      html_body: "",
      tags: [],
      comm_type: 1,
      todo: null,
      create_dt: null,
      comm_dt: "",
    })
    setShowDatePicker(false)
    await onClose()
    await handleClick({ id: data.id }, FeaturesTypes.COMMUNICATION, data)
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day")
  }

  const handleChangeRadio = useCallback(
    (e) => {
      if (data.id) {
        updateCommunicationAction({ id: data.id, comm_type: e.target.value })
      }
      setData({ ...data, comm_type: e.target.value })
    },
    [data, updateCommunicationAction]
  )
  const handleSubmit = () => {
    const values = {
      ...data,
    }
    if (!data.body?.trim()) {
      setShowWarning(true)
      return
    }
    if (!values.comm_dt) {
      values.comm_dt = moment().toISOString()
    } else {
      values.comm_dt = moment(values.comm_dt).toISOString()
    }
    values.tags = []
    if (isEdit) {
      onAdd && onAdd(values)

      if (lead?.id) {
        values.job = lead.id
      }
      if (is_communication_edit || values.id) {
        updateCommunicationAction(values)
      } else {
        createCommunicationAction(values, isEdit)
      }
    } else {
      setOpenDatePicker(true)
      notifyCreatedCommunication({
        ...values,
      })
    }
    setShowDatePicker(false)
    setData({
      body: "",
      html_body: "",
      tags: [],
      contact: null,
      comm_type: 1,
      job: null,
      todo: "",
      comm_dt: "",
    })
    onClose()
  }

  const handleAutoSaveConvo = async (d, rest, l, c) => {
    if (!d.body?.trim()) {
      setShowWarning(true)
      return
    }
    setIsRequest(true)
    const values = {
      ...d,
    }
    if (!values.comm_dt) {
      values.comm_dt = moment().toISOString()
    } else {
      values.comm_dt = moment(values.comm_dt).toISOString()
    }
    values.tags = []
    try {
      if (rest?.isEdit) {
        onAdd && onAdd(values)

        if (l?.id && !rest?.isContact) {
          values.job = l.id
        }
        if (c.id && rest?.isContact) {
          values.contact = c.id
        }
        if (values.id) {
          await updateCommunicationAction(values)
        } else {
          const res = await createCommunicationAction(values, rest?.isEdit)
          setData(res)
        }
      } else {
        setOpenDatePicker(true)
        await notifyCreatedCommunication({
          ...values,
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsRequest(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAction = useCallback(debounce(handleAutoSaveConvo, 1000), [])

  useEffect(() => {
    return () => {
      debouncedAction.cancel()
    }
  }, [debouncedAction])
  const onChangeCommunication = (e) => {
    setIsChanged(true)
    setShowWarning(false)
    setData({ ...data, body: e.target.value })
    isEdit &&
      !isRequest &&
      debouncedAction({ ...data, body: e.target.value }, rest, lead, contact)
  }
  const onChangeCommunicationQuill = (html_body, _, source, editor) => {
    setIsChanged(true)
    setShowWarning(false)
    setData({ ...data, html_body, body: editor.getText() })
    isEdit &&
      !isRequest &&
      source === "user" &&
      debouncedAction(
        { ...data, html_body, body: editor.getText() },
        rest,
        lead,
        contact
      )
  }

  const handleClose = () => {
    setShowDatePicker(false)
    setOpenDatePicker(true)
    setData({
      body: "",
      html_body: "",
      tags: [],
      contact: null,
      comm_type: 1,
      job: null,
      todo: "",
      comm_dt: "",
    })
    onClose()
    return ""
  }
  const closeDatePicker = (e) => {
    if ((e.key === "Escape" || e?.type === "blur") && openDatePicker) {
      e.stopPropagation()
      setOpenDatePicker(false)
    }
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (visible) {
        inputRef.current.focus()
        inputRef.current.editor.deleteText(
          0,
          inputRef.current.editor.getLength()
        )
      }
    }, 100)
    return () => clearTimeout(timeout)
  }, [visible])
  return {
    closeDatePicker,
    handleClose,
    handleSubmit,
    handleChangeRadio,
    radioStyle,
    data,
    setData,
    lead,
    specificCommunication,
    isChanged,
    setIsChanged,
    openDatePicker,
    setOpenDatePicker,
    showDatePicker,
    setShowDatePicker,
    showWarning,
    onChangeCommunication,
    handleClickIcon,
    pickerRef,
    disabledDate,
    inputRef,
    updateCommunicationAction,
    onChangeCommunicationQuill,
  }
}
