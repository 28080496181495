import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  PASSWORD_RESET_SEND,
  RESET_APPLICATION_DATA,
  SET_AUTH_ERRORS,
  SET_EMAIL_CHANGE_PENGING_STATUS,
  SET_EMAIL_CONFIRMED_STATUS,
  SET_EMAIL_LOGGER,
  SET_LOGIN_BAD_REQUEST_ERROR,
  SET_LOGIN_STATUS,
  SET_PASSWORD_RESET_CONFIRM_STATUS,
  SET_REFRESHING_STATUS,
  SET_TOKEN,
  SET_USER_DATA,
  SET_USER_IS_AUTH,
  CREATE_STRIPE_CUSTOMER_ID,
  CREATE_STRIPE_SUBSCRIPTION,
  SET_DESKTOP_LAYOUT,
} from "../../action-types/user"
import axios from "axios"
import { editProfileData, passwordResetReq } from "../../../api/user"
import {
  createStripeCustomer,
  createStripeSubscription,
  cancelStripeSubscription,
  createStripeIntent,
} from "../../../api/billing"
import { api } from "../../../common/config"
import { setErrorMessage, setErrorState } from "../errors/set_errors"
import { request } from "../../../api/axios_helper"
import {
  CONFIRM_USER_EMAIL_API_URL,
  GOOGLE_LOGIN_API_URL,
  LINKEDIN_LOGIN_API_URL,
  LOGIN_API_URL,
  PASSWORD_RESET_CONFIRM_API_URL,
  PROFILE_API_URL,
  PROFILE_VERIFY_API_URL,
  REGISTER_USER_API_URL,
  UPDATE_PROFILE_EMAIL_API_URL,
  DELETE_PROFILE_API_URL,
  APPLE_LOGIN_API_URL,
} from "../../../common/constants"
import { getUserData } from "../../selectors/user/getUserData"
import { resetActivities } from "../diaries/get_diaries_activities"
import { canRedirect } from "../../../common/helpers"

export const createStripeCustomerIdAction = (payload) => {
  return {
    type: CREATE_STRIPE_CUSTOMER_ID,
    payload,
  }
}

export const createStripeSubscriptionAction = (payload) => {
  return {
    type: CREATE_STRIPE_SUBSCRIPTION,
    payload,
  }
}

export const setDesktopLayout = (payload) => {
  return {
    type: SET_DESKTOP_LAYOUT,
    payload,
  }
}
export const loginUser = (token) => {
  return {
    type: LOGIN_ACTION,
    payload: token,
  }
}
export const setLoginStatus = (payload) => {
  return {
    type: SET_LOGIN_STATUS,
    payload,
  }
}
export const logoutUser = () => {
  return {
    type: LOGOUT_ACTION,
  }
}

export const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  }
}

export const createStripeCustomerAndSubscription =
  () => async (dispatch, getState) => {
    const {
      profile: { stripe_customer_id },
    } = getUserData(getState())

    let customer_id = stripe_customer_id

    try {
      if (!customer_id) {
        const customerRes = await createStripeCustomer()
        customer_id = customerRes.data.customer_id
        await dispatch(createStripeCustomerIdAction(customer_id))
      }

      const {
        data: { clientSecret },
      } = await createStripeSubscription({ customer_id })
      await dispatch(createStripeSubscriptionAction(clientSecret))
    } catch (e) {
      throw e
    }
  }

export const createStripeIntentAction = () => async (dispatch) => {
  try {
    const {
      data: { client_secret },
    } = await createStripeIntent()
    await dispatch(createStripeSubscriptionAction(client_secret))
  } catch (e) {
    throw e
  }
}

export const cancelStripeSubscriptionAction = (arg) => async (dispatch) => {
  try {
    await cancelStripeSubscription(arg)
    await dispatch(getMe())
  } catch (e) {
    throw e
  }
}

export const getMe = (token) => async (dispatch) => {
  try {
    const response = await request(token).get(PROFILE_API_URL, dispatch)
    dispatch(setUserData(response.data))
  } catch (err) {}
}
export const passwordResetSend = () => {
  return {
    type: PASSWORD_RESET_SEND,
  }
}
export const email_verify = (email) => async (dispatch) => {
  try {
    await request().create(PROFILE_VERIFY_API_URL, { email }, dispatch)
  } catch (err) {}
}
export const setEmailLogger = (msg) => {
  return {
    type: SET_EMAIL_LOGGER,
    msg,
  }
}
export const setEmailChangePendingStatus = (payload) => {
  return {
    type: SET_EMAIL_CHANGE_PENGING_STATUS,
    payload,
  }
}
export const editProfile = (data) => async (dispatch) => {
  try {
    delete data["email_verified"]
    const response = await editProfileData(data, dispatch, (err) => {
      let res = false
      if (err && "username" in err) {
        res = true
      }
      dispatch(setLoginStatus(res))
    })

    if (response) {
      dispatch(setUserData(response.data))
    }
    if (canRedirect(response.data)) {
      return true
    }
    return response?.data
  } catch (err) {
    throw err
  }
}

export const resetPassword = (email) => async (dispatch) => {
  try {
    await passwordResetReq(email)
    dispatch(passwordResetSend())
    return true
  } catch (err) {
    dispatch(setErrorMessage(err.response?.detail))
    dispatch(setErrorState(true))
  }
}

export const getCookie = (name) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export const resetApplicationData = () => (dispatch) => {
  dispatch({ type: RESET_APPLICATION_DATA })
  dispatch(resetActivities())
  localStorage.clear()
  sessionStorage.clear()
}

export const notifyServerWhenLogout = () => (dispatch) => {
  const url = `${api.auth_host}${api.urls.user_logout}`
  const config = {
    headers: {
      common: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    },
  }
  axios
    .post(url, {}, config)
    .catch((err) => {
      dispatch(setErrorMessage(err.response.detail))
      dispatch(setErrorState(true))
    })
    .finally(() => {
      window.stop()
      dispatch(logoutUser())
      localStorage.setItem("logged_in", 0)
      dispatch(resetApplicationData())
    })
}
export const setAuthErrors = (payload) => {
  return {
    type: SET_AUTH_ERRORS,
    payload,
  }
}

export const setLoginBadRequestError = (payload) => {
  return {
    type: SET_LOGIN_BAD_REQUEST_ERROR,
    payload,
  }
}
export const changeEmailConfirmedStatus = (payload) => {
  return {
    type: SET_EMAIL_CONFIRMED_STATUS,
    payload,
  }
}
export const authUser = (creds, isSignUp) => async (dispatch) => {
  try {
    const url = isSignUp ? REGISTER_USER_API_URL : LOGIN_API_URL
    const config = {
      headers: {
        common: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      },
    }
    const response = await axios.post(url, creds, config)
    dispatch(loginUser(response.data.access))
    dispatch(setUserData(response.data.user))
    return response.data
  } catch (err) {
    dispatch(setAuthErrors(err?.response?.data))
    if (
      err?.response?.data &&
      "non_field_errors" in err.response.data &&
      Array.isArray(err.response.data.non_field_errors)
    ) {
      dispatch(
        setLoginBadRequestError(err.response.data.non_field_errors.join())
      )
    }
  }
}

export const setUserIsAuth = (payload) => {
  return {
    type: SET_USER_IS_AUTH,
    payload,
  }
}
export const verifyUserToken = () => async (dispatch) => {
  try {
    await request().checkToken(
      async () => {
        await dispatch(getMe())
        await dispatch(setUserIsAuth(true))
      },
      () => {
        dispatch(setUserIsAuth(false))
      },
      dispatch
    )
  } catch (err) {
    console.log(err)
  }
}

export const confirmUserEmail = (key) => async (dispatch) => {
  try {
    let config = {}
    if (getCookie("csrftoken")) {
      config = {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    }
    await axios.post(`${CONFIRM_USER_EMAIL_API_URL}`, { key }, config)
    dispatch(changeEmailConfirmedStatus(true))
    setTimeout(() => {
      dispatch(changeEmailConfirmedStatus(null))
    }, 5000)
    dispatch(verifyUserToken())
  } catch (err) {}
}

export const setRefreshingStatus = (payload) => {
  return {
    type: SET_REFRESHING_STATUS,
    payload,
  }
}

export const setToken = (payload) => {
  return {
    type: SET_TOKEN,
    payload,
  }
}

export const changeUserEmail = (new_email) => (dispatch, getState) => {
  const profile = getUserData(getState())
  const data = {
    email: profile.email,
    new_email,
  }
  request()
    .create(UPDATE_PROFILE_EMAIL_API_URL, data)
    .then((res) => {
      const result = res?.status === 200 ? 2 : 3
      if (res?.status === 200) {
        dispatch(
          setUserData({
            ...profile,
            has_verified_email: false,
            email: new_email,
          })
        )
      }
      dispatch(setEmailChangePendingStatus(result))
    })
}

export const setResetPassConfirmStatus = (payload) => {
  return {
    type: SET_PASSWORD_RESET_CONFIRM_STATUS,
    payload,
  }
}

export const passwordResetConfirm = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(PASSWORD_RESET_CONFIRM_API_URL, data)
      .then(resolve)
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(setAuthErrors(err.response.data))
        }
        reject()
      })
  })
}

export const authWithGoogle = (access_token) => async (dispatch) => {
  try {
    const response = await axios.post(
      GOOGLE_LOGIN_API_URL,
      { access_token },
      {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    )
    dispatch(loginUser(response.data.access))
    dispatch(setUserData(response.data.user))
  } catch (err) {
    if (
      "non_field_errors" in err.response.data &&
      Array.isArray(err.response.data.non_field_errors)
    ) {
      dispatch(setErrorMessage(err.response.data.non_field_errors.join()))
      dispatch(setErrorState(true))
    }
  }
}

export const authWithApple = (data) => async (dispatch) => {
  try {
    const response = await axios.post(APPLE_LOGIN_API_URL, data, {
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    })
    dispatch(loginUser(response.data.access))
    dispatch(setUserData(response.data.user))
  } catch (err) {
    if (
      "non_field_errors" in err.response.data &&
      Array.isArray(err.response.data.non_field_errors)
    ) {
      dispatch(setErrorMessage(err.response.data.non_field_errors.join()))
      dispatch(setErrorState(true))
    }
  }
}

export const authWithLinkedin = (code) => async (dispatch) => {
  try {
    const response = await request().create(LINKEDIN_LOGIN_API_URL, { code })
    dispatch(loginUser(response.data.access_token))
    dispatch(setUserData(response.data.user))
  } catch (err) {}
}

export const deleteProfile = () => async (dispatch) => {
  try {
    await request().delete(DELETE_PROFILE_API_URL)
    dispatch(logoutUser())
    localStorage.setItem("logged_in", 0)
    dispatch(resetApplicationData())
    window.location.href = "https://savvyy.com"
  } catch (err) {}
}
