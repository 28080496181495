import {
  FETCH_COMPANIES,
  SET_SPECIFIC_COMPANY,
  SET_COMPANY_NOTES,
  SET_COMPANY_CONTACTS,
  SET_COMPANY_JOB_PENDING_STATUS,
  GET_COMPANY,
  RESET_COMPANIES,
  SET_COMPANY_TO_LIST,
} from "../../action-types/companies"
import { setPendingStatus } from "./remove-company"
import { request } from "../../../api/axios_helper"
import { COMPANY_API_URL } from "../../../common/constants"
import { getContactsByOptions } from "../../../api/contacts"
import { setContactsPendingStatus } from "../contacts/get-contacts-list"
import { getJobsByOptions } from "../../../api/jobs"
import { setJobs } from "../jobs/get-jobs"
import { getCompaniesPagination } from "../../selectors"
import { isEmpty } from "lodash"
import { getCompanyById } from "../../../api/company"

const getCompanyByIdAction = (payload) => ({
  type: GET_COMPANY,
  payload,
})

export const resetCompanies = () => ({
  type: RESET_COMPANIES,
})

const setCompanyJobsPendingStatus = (status) => ({
  type: SET_COMPANY_JOB_PENDING_STATUS,
  payload: status,
})
export const setCompaniesData = (payload) => {
  return {
    type: FETCH_COMPANIES,
    payload,
  }
}
export const setSpecificCompany = (payload) => {
  return {
    type: SET_SPECIFIC_COMPANY,
    payload,
  }
}

export const setCompanyToList = (payload) => {
  return {
    type: SET_COMPANY_TO_LIST,
    payload,
  }
}
export const getCompaniesTh = () => async (dispatch, getState) => {
  const pagination = getCompaniesPagination(getState())

  const url =
    isEmpty(pagination) || !pagination.next
      ? `${COMPANY_API_URL}?limit=50`
      : pagination.next
  try {
    dispatch(setPendingStatus(true))
    const res = await request().get(url, dispatch)
    if (res.status === 200) {
      dispatch(
        setCompaniesData({
          data: res.data.results,
          total: res.data.count,
          next: res.data.next,
        })
      )
    }
  } catch (err) {
  } finally {
    dispatch(setPendingStatus(false))
  }
}
export const setCompanyNotes = (payload) => ({
  type: SET_COMPANY_NOTES,
  payload,
})

export const setCompanyContacts = (payload) => {
  return {
    type: SET_COMPANY_CONTACTS,
    payload,
  }
}

export const getCompany = (id) => async (dispatch) => {
  try {
    const { data } = await getCompanyById(id)
    dispatch(getCompanyByIdAction(data))
  } catch (e) {
    throw e
  }
}
export const getCompanyContacts = (company_id) => (dispatch) => {
  dispatch(setContactsPendingStatus(true))
  getContactsByOptions(`?company_id=${company_id}`)
    .then((res) => {
      dispatch(setCompanyContacts(res.data))
    })
    .finally(() => dispatch(setContactsPendingStatus(false)))
}

export const getCompanyJobs = (company_id) => (dispatch) => {
  dispatch(setCompanyJobsPendingStatus(true))
  getJobsByOptions(`?company_id=${company_id}`)
    .then((res) => {
      dispatch(setJobs(res.data))
    })
    .finally(() => dispatch(setCompanyJobsPendingStatus(false)))
}

export const getCompanyNotesById = async (id) => {
  try {
    const { data } = await request().get(`${COMPANY_API_URL}${id}/find_notes/`)
    return data
  } catch (e) {
    console.log(e)
  }
}
