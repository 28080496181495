import {
  SET_UPDATED_DIARY,
  UPDATE_DIARY_ACTIVITY,
} from "../../action-types/diaries"
import { request } from "../../../api/axios_helper"
import {
  COMMUNICATION_API_URL,
  DIARIES_API_URL,
  FeaturesTypes,
} from "../../../common/constants"
import { setDiariesLoadingProgress } from "./get-diaries"

export const setUpdatedDiary = (payload) => ({
  type: SET_UPDATED_DIARY,
  payload,
})

export const updateDiary =
  (data, isUpdate = true) =>
  (dispatch) => {
    dispatch(setDiariesLoadingProgress(true))
    return request()
      .edit(`${DIARIES_API_URL}${data.id}/`, data, dispatch, "patch")
      .then((res) =>
        isUpdate
          ? dispatch(setUpdatedDiary(res.data))
          : {
              payload: res.data,
            }
      )
      .finally(() => dispatch(setDiariesLoadingProgress(false)))
  }
export const updateDiaryActivity = (payload) => {
  return {
    type: UPDATE_DIARY_ACTIVITY,
    payload,
  }
}
export const editCommunicationInDiary =
  (data, diary_date) => async (dispatch) => {
    try {
      if (!data.tags) delete data.tags
      const response = await request().edit(
        `${COMMUNICATION_API_URL}${data.id}/`,
        data,
        dispatch,
        "patch"
      )
      dispatch(
        updateDiaryActivity({
          data: response.data,
          diary_date,
          activity_key: FeaturesTypes.COMMUNICATION,
        })
      )
    } catch (e) {}
  }
