import React, { useState } from "react"
import { Button, Input, Typography, Col, Row } from "antd"
import { resetPassword } from "../store/actions/user"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import { Link, useHistory } from "react-router-dom"
import { URLS } from "../common/urls"
import Logo from "../assets/images/logo.svg"
import { Alert } from "antd"

const { Title, Text } = Typography

const emailSchema = yup.string().email()
export const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const handleChangeEmail = (e) => {
    isValid(e.target.value)
    setEmail(e.target.value)
  }
  const isValid = (email) => {
    emailSchema.isValid(email).then((res) => {
      if (!res) {
        setDisabled(true)
        setShowWarning(true)
      } else {
        setShowWarning(false)
        setDisabled(false)
      }
    })
  }
  const handlePasswordReset = () => {
    dispatch(resetPassword(email)).then((res) => {
      res &&
        history.push({
          pathname: URLS.LOGIN,
          state: {
            message: `Thanks ${email}, the verification link has been will be sent to your account`,
          },
        })
    })
  }
  return (
    <>
      <Row>
        <Col
          className="logo-cols"
        >
          <div>
            <a href="https://savvyy.com/">
              <img src={Logo} alt="" />
            </a>
          </div>
        </Col>
        <Col
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row style={{ display: "block", width: "422px" }} className="register-container">
            {showWarning && (
              <Alert
                message="Please write a valid email"
                className="error-alert"
                type="error"
              />
            )}
            <div
              className="auth-form"
            >
              <Row>
                <div style={{ width: "100%" }}>
                  <Title
                    style={{ marginBottom: "32px", textAlign: "center" }}
                    level={3}
                  >
                    Forgot password?
                  </Title>
                  <div className="authForm">
                    <Text style={{ display: "block", marginBottom: "8px" }}>
                      Please write your email here
                    </Text>
                    <Input
                      value={email}
                      onChange={handleChangeEmail}
                      placeholder="Email"
                      style={{
                        width: "100%",
                        height: "32px",
                        border: "1px solid #D9D9D9",
                        borderRadius: "2px",
                      }}
                    />
                    <Button
                      onClick={handlePasswordReset}
                      type="primary"
                      disabled={disabled}
                      style={{
                        display: "block",
                        width: "100%",
                        marginBottom: "20px",
                        marginTop: "24px",
                        color: "#ffffff",
                        backgroundColor: "#009EEF",
                        borderRadius: "2px",
                        borderColor: "#009EEF",
                      }}
                    >
                      Send
                    </Button>
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "#009EEF",
                        textDecoration: "underline",
                      }}
                      to={URLS.LOGIN}
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Col>
      </Row>
      <div>
        <div></div>
      </div>
    </>
  )
}
