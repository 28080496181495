import React, { useCallback, useState } from "react"
import { Timeline, Spin } from "antd"
import DiaryItem from "./diaries/diary_item"
import { useDispatch, useSelector } from "react-redux"
import {
  getContactsSelector,
  getDiariesActivitiesSelector,
  getDiariesActivityIsPending,
  getDiariesPendingStatus,
} from "../store/selectors"
import {
  resetActivities,
  thunkGetActivies,
} from "../store/actions/diaries/get_diaries_activities"
import "../styles/diaries.css"
import { DiaryDrawer } from "./diaries/diary-drawer"
import { setSpecificNote } from "../store/actions/notes/create_note"
import { setCurrentDiaryDate } from "../store/actions/notes/edit_note"
import { getDiaries } from "../store/actions"
import { getCommunicationEditStatus } from "../store/selectors/communications/get-communications-list"
import { getContacts } from "../store/actions/contacts/get-contacts-list"
import { isEmpty } from "lodash"
import EmptyDiaryIcon from '../assets/images/Diaries.svg'

export const TimeLine = ({ items, handleOpenEditor, showEditor = false }) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [diaryHtml, setDiaryHtml] = useState("")
  const [diaryContent, setDiaryContent] = useState("")
  const dispatch = useDispatch()
  const isPending = useSelector(getDiariesPendingStatus)
  const activities = useSelector(getDiariesActivitiesSelector)
  const activity_is_pending = useSelector(getDiariesActivityIsPending)
  const communicationIsEdit = useSelector(getCommunicationEditStatus)
  const contacts = useSelector(getContactsSelector)
  const handleCloseDrawer = useCallback((e) => {
    if (communicationIsEdit && e.type !== "click") {
      return
    }
    setOpenDrawer(false)
    setDiaryContent("")
    dispatch(setCurrentDiaryDate(""))
    setDiaryHtml("")
    dispatch(setSpecificNote({}))
    dispatch(resetActivities())
    dispatch(getDiaries(false))
  }, [communicationIsEdit, dispatch])
  const handleOpenDrawer = useCallback(
    (e) => {
      if (isEmpty(contacts)) {
        dispatch(getContacts())
      }
      const _date = e.currentTarget.dataset.date
      const config = JSON.parse(e.currentTarget.dataset.config)
      const diary = JSON.parse(e.currentTarget.dataset.diary)
      dispatch(thunkGetActivies(config, _date))
      dispatch(setSpecificNote(diary))
      dispatch(setCurrentDiaryDate(_date))
      setOpenDrawer(true)
      setDiaryContent(e.currentTarget.dataset.content)
      setDiaryHtml(e.currentTarget.dataset.diaryHtml)
    },
    [contacts, dispatch]
  )
  const myItems = Object.keys(items).map((item) => {
    return {
      color: "#1890ff",
      children: (
        <DiaryItem
          handleOpenDrawer={handleOpenDrawer}
          activity_type={item}
          items={items}
          key={item}
          activity_is_pending={activity_is_pending}
        />
      )
    }
  })
  return (
    <>
      <div style={{
        textAlign: "center",
        position: "absolute",
        top: "20%",
        left: "45%",
        zIndex: 20,
      }}>
        {isPending && !showEditor && <Spin />}
      </div>
      <div className="desktop-timeline">
        {isEmpty(items) ? (
          <div style={{
            minHeight: 355,
            padding: "80px 0px 0px 45px",
            opacity: isPending ? 0.3 : 1
          }}>
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <div className="home-empty-image-container">
                <img src={EmptyDiaryIcon} alt="no diaries" className="home-empty-image" />
              </div>
              <span className="home-empty-lead-text" style={{

                lineHeight: "25px",
              }}>
                You’ve come a long way. Keep a diary. See your journey,
                review your activities, reflect and decide what’s next.
              </span>
            </div>
          </div>

        ) : (
          <Timeline style={{ minHeight: 355 }} items={myItems} />
        )}
      </div>
      {openDrawer && (
        <DiaryDrawer
          diaryHtml={diaryHtml}
          open={openDrawer}
          diaryContent={diaryContent}
          onClose={handleCloseDrawer}
          activities={activities}
          activities_is_pending={activity_is_pending}
          setDiaryContent={setDiaryContent}
          setDiaryHtml={setDiaryHtml}
        />
      )}
    </>
  )
}
