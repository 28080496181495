/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { AppleFilled } from "@ant-design/icons"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Button, Card, Alert } from "antd"
import { useDispatch } from "react-redux"
import { getStripeStatus, getPaymentInfo } from "../../api/billing"
import {
  createStripeCustomerAndSubscription,
  cancelStripeSubscriptionAction,
  createStripeIntentAction,
  createStripeSubscriptionAction,
} from "../../store/actions/user"
import { config, DATE_FORMAT } from "../../common/config"
import moment from "moment"
import { isEmpty } from "lodash"
import { getMe } from "../../store/actions/user"

import CheckoutForm from "./form"
import CancelSubscription from "./confirm"
import { DeleteAccount } from "../modals/delete-account"
import { deleteProfile } from "../../store/actions/user"
import { EXPORT_DATA_API_URL } from "../../common/constants"
import { useAction } from "../../hooks/useAction"
import { ReactComponent as GoogleIcon } from "../../assets/images/google_icon.svg";



const stripePromise = loadStripe(
  config.stripe_key
)

const capitalizeWord = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const BillingComponent = ({ clientSecret, profile, action, paymentInfo, setPaymentInfo }) => {

  const [isIntent, setIsIntent] = useState(false)
  const [billingDetails, setBillingDetails] = useState(null)
  const [isCancel, setIsCancel] = useState(false)

  const deleteAccountAction = useAction(deleteProfile)

  useEffect(() => {
    dispatch(getMe())
  }, [])

  useEffect(() => {
    !billingDetails && action && action === "PMTSUCC"
      &&
      getStripeStatus(profile?.profile?.subscription_processor)
        .then((res) => {
          setBillingDetails(res.data)
        })

  }, [billingDetails, action])

  useEffect(() => {
    if (!paymentInfo && billingDetails?.subscription_status === "active") {
      if (profile?.profile?.subscription_processor === 'STRIPE') {
        getPaymentInfo().then((res) => {
          setPaymentInfo(res.data)
        })
      }
    }

  }, [paymentInfo, billingDetails])


  const dispatch = useDispatch()

  const handleClickCreateSubscription = () => dispatch(createStripeCustomerAndSubscription())

  const handleClickEditSubscription = () => {
    setIsIntent(true)
    dispatch(createStripeIntentAction())
  }

  const handleClickCancelSubscription = async () => {
    dispatch(cancelStripeSubscriptionAction({ subscription_id: billingDetails.subscription_id })).then(() => {
      setBillingDetails(null)
      setPaymentInfo(null)
    })
    setIsCancel(false)
  }

  const handleCancelPayment = () => dispatch(createStripeSubscriptionAction(""))
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: "flex", alignItems: "center", paddingTop: 30 }}>
        {!isEmpty(profile?.profile?.redirect_profile?.subscription_flags) && (
          <Alert
            type={(action === "PMTFAIL" || action === "PMTREQ" || action === "RENEW" || action === "PMTMISSING") ? "error" : "info"}
            message={profile?.profile?.redirect_profile?.subscription_flags?.message}
            style={{ marginRight: "50px" }}
          />
        )}
        {action === "PMTREQ" && !clientSecret && <SubscriptionButton onClick={handleClickCreateSubscription} title="Subscribe" />}
        {action === "PMTFAIL" && !clientSecret && <SubscriptionButton onClick={handleClickCreateSubscription} title="Add New Payment" />}
        {action === "RENEW" && !clientSecret && <SubscriptionButton onClick={handleClickCreateSubscription} title="Renew Subscription" />}
        {action === "PMTMISSING" && !clientSecret && <SubscriptionButton onClick={handleClickEditSubscription} title="Add Payment Details" />}
      </div>
      <div style={{ display: action?.includes("PMT") && billingDetails?.latest_invoice_status ? "flex" : "block", marginTop: "40px" }}>
        <div style={{ width: 500, marginRight: "120px" }}>
          <div>
            {action?.includes("PMT") && billingDetails?.latest_invoice_status && <BillingDetails billingDetails={billingDetails} />}
            {paymentInfo && billingDetails?.subscription_status === "active" && <PaymentInfo paymentInfo={paymentInfo} />}
            {billingDetails?.subscription_status === "active" && <>
              {profile?.profile?.subscription_processor === "APPLE" &&
                <div>
                  <AppleFilled />
                  <div style={{ transform: "translate(20px, -25px)" }}>
                    You can manage this subscription along with your other IOS subscriptions on your phone.
                  </div>
                </div>
              }
              {profile?.profile?.subscription_processor === "GOOGLE" &&
                <div>
                  <GoogleIcon />
                  <div style={{ transform: "translate(20px, -25px)" }}>
                    You can manage this subscription along with your other Android subscriptions on your phone.
                  </div>
                </div>
              }
            </>}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {action === "PMTSUCC" && profile?.profile?.subscription_processor === "STRIPE" && <SubscriptionButton onClick={() => setIsCancel(true)} title="Cancel" />}
            {!clientSecret && billingDetails?.subscription_status === "active" && profile?.profile.subscription_processor === "STRIPE" && <SubscriptionButton onClick={(handleClickEditSubscription)} title="Edit Payment Details" />}
          </div>
        </div>

        <div style={{ width: 400 }}>
          {clientSecret && <StripeComponent clientSecret={clientSecret} return_url={`${config.self_host}verify`} isIntent={isIntent} onCancel={handleCancelPayment} />}
        </div>
      </div>
      <CancelSubscription visible={isCancel} handleClose={() => setIsCancel(false)} handleClickCancel={handleClickCancelSubscription} />
      {!clientSecret && (
        <div style={{ position: "absolute", top: 36, right: 200 }}>
          <Button type="primary" onClick={() => window.open(EXPORT_DATA_API_URL)} >Export Data</Button>
          <DeleteAccount onDelete={deleteAccountAction} />

        </div>
      )}
    </div>
  )
}


const SubscriptionButton = ({ onClick, title }) => {
  return <Button onClick={onClick} type="primary">{title}</Button>
}




const PaymentInfo = ({ paymentInfo }) => {

  const { brand, exp_month, exp_year, last4 } = paymentInfo

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "40px" }}>
      <h3 style={{ margin: 0 }}>Payment Method:</h3>
      <Card size="small" bodyStyle={{ padding: "3px 10px", width: "300px" }}>
        <span> {brand && capitalizeWord(brand)}, {last4 && `Last 4: ${last4}`} {exp_month && exp_year && `Exp: ${exp_month}/${exp_year}`} </span>
      </Card>
    </div>
  )

}



const BillingDetails = ({ billingDetails }) => {
  const { subscription_status, latest_invoice_paid_dt, total, latest_invoice_status } = billingDetails
  return <div>

    {subscription_status &&
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "22px" }}>
        <h3 style={{ marginBottom: 0 }}>Subscription status:</h3>
        <Card size="small" bodyStyle={{ padding: "3px 10px", width: "300px" }}>
          {capitalizeWord(subscription_status)}
        </Card>
      </div>}



    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "22px" }}>
      <h3 style={{ marginBottom: 0 }}>Last Invoice:</h3>
      <Card size="small" bodyStyle={{ padding: "3px 10px", width: "300px" }}>
        <span> {latest_invoice_status && capitalizeWord(latest_invoice_status)}: {total && "$" + (total / 100)} {latest_invoice_paid_dt && moment(latest_invoice_paid_dt).format(DATE_FORMAT)} </span>
      </Card>
    </div>

  </div>
}

const StripeComponent = ({ clientSecret, return_url, isIntent, onCancel }) => {
  const options = {
    clientSecret,
    appearance: {
      variables: {
        borderRadius: '0px',
        fontSizeSm: '13px'
      },
      rules: {
        '.Input': {
          fontSize: '14px',
          padding: '7px'
        },
      }
    },
  };

  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm clientSecret={clientSecret} return_url={return_url} isIntent={isIntent} onCancel={onCancel} />
      </Elements>
    </>
  )
}

export default BillingComponent
