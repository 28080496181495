/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useRef, useState, useEffect } from "react"
import { Button, Drawer, Popconfirm } from "antd"
import { CloseOutlined, ExpandAltOutlined } from "@ant-design/icons"
import { ReactComponent as CollapseExpandIcon } from '../../assets/images/collapse-svgrepo-com (1).svg'
import { useSelector } from "react-redux"
import {
  // getCommunicationsSelector,
  getSpecificCommunication,
} from "../../store/selectors/communications/get-communications-list"
import { useAction } from "../../hooks/useAction"
import { removeCommunication } from "../../store/actions/communication/remove-communication"
import { updateCommunication } from "../../store/actions/communication/update-communication"
import { setSpecificCommunication } from "../../store/actions/communication/create-communication"
import { QuilEditor } from "../editor/quil-editor"
import Text from "antd/es/typography/Text"
import { DATE_FORMAT } from "../../common/config"
import moment from "moment"
import { debounce } from "lodash"
import Spinner from '../loaders/spinner'
import { useCommunicationSpecData } from "../../hooks/useSpecData"


export const CommunicationDrawer = ({ onClose }) => {
  const specificCommunication = useSelector(getSpecificCommunication)
  const loading = useSelector(state => state.communications.pending)
  // const communications = useSelector(getCommunicationsSelector)
  const setCommunication = useAction(setSpecificCommunication)
  const { job: specificJob, contact: specificContact, communicationDataId } = useCommunicationSpecData(specificCommunication)
  const actionRemove = useAction(removeCommunication)
  const updateCommunicationAc = useAction(updateCommunication)
  const [show, setShow] = useState(false)
  const [modalWidth, setModalWidth] = useState(600)
  const editorRef = useRef()
  const handleRemoveCommunication = () => {
    actionRemove(specificCommunication.id)
    onClose(specificCommunication.id)
  }
  const onExpandClick = () => {
    setModalWidth(modalWidth === 600 ? "90vw" : 600)
    editorRef.current.focus()
  }
  // const handleEditNote = (e) => {
  //   const isPublished =
  //     !isNaN(specificCommunication.id) ||
  //     communications.published.some(
  //       (item) => item.id === specificCommunication.id
  //     )
  //   if (isPublished) {
  //     updateCommunicationAc(specificCommunication)
  //   } else {
  //     updateCommunicationAc(specificCommunication, null, true)
  //   }
  //   onClose()
  // }
  const actionAfterTyping = (d) => {

    if (d.id) {
      updateCommunicationAc(d)
    }
  }


  const debouncedAction = useCallback(debounce(actionAfterTyping, 1000), [])

  const handleChangeNote = useCallback(
    (html_body, deltaObj, source, editor) => {
      if (editorRef?.current?.focus) {
        editorRef.current.focus()
      }
      setCommunication({
        ...specificCommunication,
        html_body,
        body_editor: editor.getContents(),
        body: editor.getText(),
      })
      if (source === 'user') {
        debouncedAction({
          ...specificCommunication,
          html_body,
          body_editor: editor.getContents(),
          body: editor.getText(),
        })
      }

    },

    [setCommunication, specificCommunication]
  )
  useEffect(() => {
    return () => {
      debouncedAction.cancel()
    }
  }, [debouncedAction])
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setShow(true), 1)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Drawer
      className={`conversation-drawer ${modalWidth === 600 ? "" : "big-convo"}`}
      title={
        <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ textAlign: "center", display: "flex" }}>
            <Text>
              {specificContact?.first_name && specificCommunication.contact && (
                <span>
                  {specificContact?.first_name || ""}
                  {specificContact?.company?.name ? (" @ " + specificContact?.company?.name) : ""}
                </span>
              )}
              {specificJob?.title && specificCommunication.job && (
                <span>
                  {specificJob?.title || ""}
                  {specificJob?.company?.name ? (" @ " + specificJob?.company?.name) : ""}
                </span>
              )}

            </Text>
            {communicationDataId && <span style={{ margin: '0px 4px 0px 4px' }}> - </span>}

            <Text>
              Convo:
              {specificCommunication.comm_type === 1
                ? " Email "
                : specificCommunication.comm_type === 2
                  ? " Phone "
                  : specificCommunication.comm_type === 3
                    ? " Meeting "
                    : specificCommunication.comm_type === 4
                      ? " LinkedIn "
                      : " "}
              on {moment(specificCommunication.comm_dt).format(DATE_FORMAT)}
            </Text>
          </div>
          <div style={{ display: "flex" }}>
            <div onClick={onExpandClick} className="expand-icon" style={{ padding: "2px 1px 2px 1px" }}>
              {modalWidth !== 600 ? <CollapseExpandIcon /> : <ExpandAltOutlined style={{ fontSize: 20 }} />}

            </div>
            <CloseOutlined className="expand-icon" style={{ padding: "2px 1px 2px 1px", fontSize: "20px" }} onClick={onClose} />
          </div>
        </div >

      }
      placement={"left"}
      zIndex={2000}
      width={modalWidth}
      open
      onClose={onClose}
      closeIcon={false}
    >
      <div className={modalWidth === 600 ? "" : "big-convo-quill"} style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "2%", right: "5%" }}>
          {loading && <Spinner />}
        </div>
        {show &&
          <QuilEditor
            is_diary
            focus
            editorRef={editorRef}
            data-element={"textarea"}
            content={specificCommunication?.body_editor?.ops ? specificCommunication?.body_editor : specificCommunication?.html_body || specificCommunication?.body}
            onChange={handleChangeNote}
          />
        }
      </div>




      <div className="conversation-drawer__actions df jc_sb">

        <Popconfirm
          placement="topRight"
          className="delete_file"
          title="Delete communication? There is no undo."
          onConfirm={handleRemoveCommunication}
          okText="Delete"
          cancelText="Cancel"
          zIndex={2000}
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>

        {/* <div>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button style={{ marginLeft: "10px" }} type="primary" onClick={handleEditNote}>
            Save
          </Button>
        </div> */}
      </div>
    </Drawer >
  )
}
