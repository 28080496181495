import { createSelector } from "reselect"
import { isEmpty, uniqBy } from "lodash"
const getContacts = (state) => state.contacts

export const getContactsPagination = createSelector(
  getContacts,
  (state) => state.pagination
)

export const getContactsSelector = createSelector(getContacts, (val) => {
  if (!Array.isArray(val.items) && isEmpty(val.items)) {
    return []
  }
  // return val.items.sort((a, b) => {
  //   return a?.first_name
  //     ?.toLowerCase()
  //     .localeCompare(b?.first_name?.toLowerCase())
  // })
  return val.items
})

export const getSortKey = createSelector(getContacts, (state) => state.sortKey)

export const getSpecificContact = createSelector(getContacts, (val) => {
  // if (val.specificContact?.company) {
  //   val.specificContact.company = {
  //     ...val.specificContact.company,
  //     tags: val.specificContact.tags ? val.specificContact.tags : [],
  //   }
  // }
  return val.specificContact
})

export const getContactTodosSelector = createSelector(
  getContacts,
  (contact_state) => {
    const todos = uniqBy([...(contact_state.todos || [])], "id")
    return [...todos].sort((a, b) => a.drag_index - b.drag_index)
  }
)

export const getContactsTotalCount = createSelector(getContacts, (state) => {
  return state.total_count
})

export const getContactDraftTags = createSelector(getContacts, (state) => {
  return state?.draftTags
})
export const getNotContactedCount = createSelector(getContacts, (state) => {
  return state.not_contacted
})
export const getContactsPendingStatus = createSelector(
  getContacts,
  (state) => state.isPending
)
export const getSearchContactResults = createSelector(
  getContacts,
  (state) => state.searchResults
)

export const getContactSearchLoading = createSelector(getContacts, (state) => {
  return state.searchPending
})
