import {
  REMOVE_DRAFT_COMMUNICATION,
  REMOVE_PUBLISHED_COMMUNICATION,
} from "../../action-types/communication"
import { setCommunicationPendingStatus } from "./update-communication"
import { request } from "../../../api/axios_helper"
import { COMMUNICATION_API_URL, FeaturesTypes } from "../../../common/constants"
import { removeSearchResult } from "../search/get-search"
import { getCommunicationsSelector } from "../../selectors"
import { removeDiaryActivity } from "../diaries/delete-diary"

export const removeDraftCommunication = (id) => {
  return {
    type: REMOVE_DRAFT_COMMUNICATION,
    payload: id,
  }
}
export const removePublishedCommunications = (id) => {
  return {
    type: REMOVE_PUBLISHED_COMMUNICATION,
    payload: id,
  }
}
export const removeCommunication =
  (id, isDraft) => async (dispatch, getState) => {
    try {
      const communications = getCommunicationsSelector(getState())
      const isPublished = communications.published.some(
        (item) => item.id === id
      )
      dispatch(setCommunicationPendingStatus(true))
      if (isDraft || !isPublished) {
        dispatch(removeDraftCommunication(id))
        dispatch(setCommunicationPendingStatus(false))
        return
      }
      if (isPublished) {
        await request().delete(
          `${COMMUNICATION_API_URL}${id}/`,
          dispatch,
          () => {
            dispatch(removePublishedCommunications(id))
            dispatch(removeSearchResult(id, "communication"))
          }
        )
      }
      dispatch(
        removeDiaryActivity({ id, activity_type: FeaturesTypes.COMMUNICATION })
      )
      dispatch(setCommunicationPendingStatus(false))
    } catch (err) {}
  }
